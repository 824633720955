import ExcelJS from 'exceljs'

export function getDefaultMonthYear(): {
  defaultYear: number
  defaultMonth: number
} {
  const defaultYear = new Date(new Date().getFullYear(), new Date().getMonth() - 1).getFullYear()
  const defaultMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1).getMonth()

  return {
    defaultYear: defaultYear,
    defaultMonth: defaultMonth,
  }
}

export function useFilterYearMonth() {
  const monthListOption = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December  ', value: 11 },
  ]

  const yearsListOption = []

  const year = new Date().getFullYear()

  const startYear = 2022
  // Get List of Year start 2022 to now
  for (let i = startYear; i <= year; i++) {
    yearsListOption.push({ label: `${i}`, value: i })
  }

  return {
    monthListOption,
    yearsListOption: yearsListOption.sort((a, b) => a.value - b.value),
  }
}

enum ETagColor {
  RED = 'RED',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  GRAY = 'GRAY',
}

enum ETagType {
  RETAILER = 'RETAILER',
  PRODUCT = 'PRODUCT',
}

interface ITags {
  tagID?: number
  tagName?: string
  isActive?: boolean
  tagColor?: ETagColor
  typeTag?: ETagType
}

interface RetailerData {
  retailerID: number
  retailerCode: string
  retailerName: string
  isActive: boolean
  zaloID: string
  phoneNumber: string
  email: string
  sourceType: string
  zoneName: string
  provinceName: string
  districtName: string
  segmentName: string
  countryID?: number
  addressID?: number
  districtID?: number
  provinceID?: number
  segmentID?: number
  zoneID?: number
  tagList?: ITags[]
}

export const customerExcel = async (data: RetailerData[]): Promise<ExcelJS.Buffer> => {
  console.log(data)

  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Loyalty Hub Autocreate'
  workbook.lastModifiedBy = 'Loyalty Hub Autocreate'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet = workbook.addWorksheet('Retailer')

  sheet.getCell('A1').value = 'retailerID'
  sheet.getRow(1).height = 28.8
  sheet.getRow(1).alignment = { vertical: 'middle' }
  sheet.getCell('B1').value = 'retailerCode'
  sheet.getCell('C1').value = 'retailerName'
  sheet.getCell('D1').value = 'isActive'
  sheet.getCell('E1').value = 'zaloID'
  sheet.getCell('F1').value = 'phoneNumber'
  sheet.getCell('G1').value = 'email'
  sheet.getCell('H1').value = 'sourceType'
  sheet.getCell('I1').value = 'zoneName'
  sheet.getCell('J1').value = 'provinceName'
  sheet.getCell('K1').value = 'districtName'
  sheet.getCell('L1').value = 'segmentName'

  data.map((element, index) => {
    // BEWARE OF AA!
    console.log(index + 2, 'index + 2')
    sheet.getCell('A' + (index + 2)).value = element.retailerID
    sheet.getCell('B' + (index + 2)).value = element.retailerCode
    sheet.getCell('C' + (index + 2)).value = element.retailerName
    sheet.getCell('D' + (index + 2)).value = element.isActive
    sheet.getCell('E' + (index + 2)).value = element.zaloID
    sheet.getCell('F' + (index + 2)).value = element.phoneNumber
    sheet.getCell('G' + (index + 2)).value = element.email
    sheet.getCell('H' + (index + 2)).value = element.sourceType
    sheet.getCell('I' + (index + 2)).value = element.zoneName
    sheet.getCell('J' + (index + 2)).value = element.provinceName
    sheet.getCell('K' + (index + 2)).value = element.districtName
    sheet.getCell('L' + (index + 2)).value = element.segmentName
  })

  sheet.columns.forEach(function (column, i) {
    let maxLength = 0
    if (column['eachCell']) {
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 13
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength + 3
    }
  })

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

interface BigRetailerData {
  bigRetailerID: number
  bigRetailerCode: string
  bigRetailerName: string
  isActive: boolean
  zaloID: string
  phoneNumber: string
  email: string
  sourceType: string
  zoneName: string
  provinceName: string
  districtName: string
  segmentName: string
  ownRetailerID?: number
  ownRetailerName?: string
  countryID?: number
  addressID?: number
  districtID?: number
  provinceID?: number
  segmentID?: number
  zoneID?: number
  tagList?: ITags[]
}

export const bigCustomerExcel = async (data: BigRetailerData[]): Promise<ExcelJS.Buffer> => {
  console.log(data)

  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Loyalty Hub Autocreate'
  workbook.lastModifiedBy = 'Loyalty Hub Autocreate'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet = workbook.addWorksheet('BigRetailer')

  sheet.getCell('A1').value = 'bigRetailerID'
  sheet.getRow(1).height = 28.8
  sheet.getRow(1).alignment = { vertical: 'middle' }
  sheet.getCell('B1').value = 'bigRetailerCode'
  sheet.getCell('C1').value = 'bigRetailerName'
  sheet.getCell('D1').value = 'isActive'
  sheet.getCell('E1').value = 'zaloID'
  sheet.getCell('F1').value = 'phoneNumber'
  sheet.getCell('G1').value = 'email'
  sheet.getCell('H1').value = 'sourceType'
  sheet.getCell('I1').value = 'ownRetailerID'
  sheet.getCell('J1').value = 'ownRetailerName'
  sheet.getCell('K1').value = 'zoneName'
  sheet.getCell('L1').value = 'provinceName'
  sheet.getCell('M1').value = 'districtName'
  sheet.getCell('N1').value = 'segmentName'

  data.map((element, index) => {
    // BEWARE OF AA!
    console.log(index + 2, 'index + 2')
    sheet.getCell('A' + (index + 2)).value = element.bigRetailerID
    sheet.getCell('B' + (index + 2)).value = element.bigRetailerCode
    sheet.getCell('C' + (index + 2)).value = element.bigRetailerName
    sheet.getCell('D' + (index + 2)).value = element.isActive
    sheet.getCell('E' + (index + 2)).value = element.zaloID
    sheet.getCell('F' + (index + 2)).value = element.phoneNumber
    sheet.getCell('G' + (index + 2)).value = element.email
    sheet.getCell('H' + (index + 2)).value = element.sourceType
    sheet.getCell('I' + (index + 2)).value = element.ownRetailerID
    sheet.getCell('J' + (index + 2)).value = element.ownRetailerName
    sheet.getCell('K' + (index + 2)).value = element.zoneName
    sheet.getCell('L' + (index + 2)).value = element.provinceName
    sheet.getCell('M' + (index + 2)).value = element.districtName
    sheet.getCell('N' + (index + 2)).value = element.segmentName
  })

  sheet.columns.forEach(function (column, i) {
    let maxLength = 0
    if (column['eachCell']) {
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 13
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength + 3
    }
  })

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

interface ICampaignTargetTypeValue {
  targetTypeID: number
  targetTypeName: string
}

interface IRewardPointList {
  pointReward: any
  rewardPointID: number
  targetTypeID: number
  targetTypeName: string
}

interface ILevel {
  level: string
  point: number
}

interface ITargetProduct {
  levelList: ILevel[]
  tagID: number
  tagName: string
}

interface ICampaign {
  campaignID: string
  campaignName: string
  campaignShopType?: string | null
  campaignStatus?: string
  campaignTargetType?: string
  campaignTargetTypeValue?: ICampaignTargetTypeValue[] | null
  campaignType?: string | null
  countryName?: string
  pointStatus?: string | null
  promotion?: string[]
  restrictionEndDate: string
  restrictionStartDate: string
  shopTagsList?: ITags[]
  updatedAt?: string
  remarkPoint?: string | null

  campaignDescription?: string
  campaignGoal?: string
  documents?: string[]
  isSubRTLCal?: boolean
  remark?: string | null
  res_code?: string
  res_desc?: string
  rewardPointList?: IRewardPointList[] | null
  targetProductList?: ITargetProduct[] | null
}

interface IReviewPointCampaign {
  activeCampaign: ICampaign[]
  expiredCampaign: ICampaign[]
}

const convertDate = (date: string) => {
  const newDate = new Date(date)
  return newDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

export const reviewPointCampaign = async (data: IReviewPointCampaign): Promise<ExcelJS.Buffer> => {
  console.log(data)

  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Loyalty Hub Autocreate'
  workbook.lastModifiedBy = 'Loyalty Hub Autocreate'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet = workbook.addWorksheet('ReviewPointCampaign')

  sheet.getCell('A1').value = 'Active Campaign'
  sheet.getRow(1).height = 28.8
  sheet.getRow(1).alignment = { vertical: 'middle' }

  sheet.getCell('A2').value = 'Campaigns ID'
  sheet.getRow(2).height = 28.8
  sheet.getRow(2).alignment = { vertical: 'middle' }
  sheet.getCell('B2').value = 'Campaigns Name'
  sheet.getCell('C2').value = 'Target Type'
  sheet.getCell('D2').value = 'Target Type Value'
  sheet.getCell('E2').value = 'Tags Retailer'
  sheet.getCell('F2').value = 'Campaign Type'
  sheet.getCell('G2').value = 'Time line'
  sheet.getCell('H2').value = 'Country'
  sheet.getCell('I2').value = 'Last Update'
  sheet.getCell('J2').value = 'Status'
  sheet.getCell('K2').value = 'Point Approval'

  data.activeCampaign.map((element, index) => {
    // BEWARE OF AA!
    sheet.getCell('A' + (index + 3)).value = element.campaignID
    sheet.getCell('B' + (index + 3)).value = element.campaignName
    sheet.getCell('C' + (index + 3)).value = element.campaignTargetType
    sheet.getCell('D' + (index + 3)).value = element.campaignTargetTypeValue
      ?.map((e) => e.targetTypeName + '  ')
      .toString()
    sheet.getCell('E' + (index + 3)).value = element.shopTagsList?.map((e) => e.tagName + '  ').toString()
    sheet.getCell('F' + (index + 3)).value = element.campaignType
    sheet.getCell('G' + (index + 3)).value =
      'Start :' + convertDate(element.restrictionStartDate) + '   End :' + convertDate(element.restrictionEndDate)
    sheet.getCell('H' + (index + 3)).value = element.countryName
    sheet.getCell('I' + (index + 3)).value = element.updatedAt
    sheet.getCell('J' + (index + 3)).value = element.campaignStatus
    sheet.getCell('K' + (index + 3)).value = element.pointStatus
  })

  sheet.getCell('A' + (data.activeCampaign.length + 4)).value = 'Expired Campaign'
  sheet.getRow(data.activeCampaign.length + 4).height = 28.8
  sheet.getRow(data.activeCampaign.length + 4).alignment = { vertical: 'middle' }
  sheet.getCell('A' + (data.activeCampaign.length + 5)).value = 'Campaigns ID'
  sheet.getRow(data.activeCampaign.length + 5).height = 28.8
  sheet.getRow(data.activeCampaign.length + 5).alignment = { vertical: 'middle' }
  sheet.getCell('B' + (data.activeCampaign.length + 5)).value = 'Campaigns Name'
  sheet.getCell('C' + (data.activeCampaign.length + 5)).value = 'Target Type'
  sheet.getCell('D' + (data.activeCampaign.length + 5)).value = 'Target Type Value'
  sheet.getCell('E' + (data.activeCampaign.length + 5)).value = 'Tags Retailer'
  sheet.getCell('F' + (data.activeCampaign.length + 5)).value = 'Campaign Type'
  sheet.getCell('G' + (data.activeCampaign.length + 5)).value = 'Time line'
  sheet.getCell('H' + (data.activeCampaign.length + 5)).value = 'Country'
  sheet.getCell('I' + (data.activeCampaign.length + 5)).value = 'Last Update'
  sheet.getCell('J' + (data.activeCampaign.length + 5)).value = 'Status'
  sheet.getCell('K' + (data.activeCampaign.length + 5)).value = 'Point Approval'

  data.expiredCampaign.map((element, index) => {
    // BEWARE OF AA!
    sheet.getCell('A' + (index + data.activeCampaign.length + 6)).value = element.campaignID
    sheet.getCell('B' + (index + data.activeCampaign.length + 6)).value = element.campaignName
    sheet.getCell('C' + (index + data.activeCampaign.length + 6)).value = element.campaignTargetType
    sheet.getCell('D' + (index + data.activeCampaign.length + 6)).value = element.campaignTargetTypeValue
      ?.map((e) => e.targetTypeName + '  ')
      .toString()
    sheet.getCell('E' + (index + data.activeCampaign.length + 6)).value = element.shopTagsList
      ?.map((e) => e.tagName + '  ')
      .toString()
    sheet.getCell('F' + (index + data.activeCampaign.length + 6)).value = element.campaignType
    sheet.getCell('G' + (index + data.activeCampaign.length + 6)).value =
      'Start :' + convertDate(element.restrictionStartDate) + '   End :' + convertDate(element.restrictionEndDate)
    sheet.getCell('H' + (index + data.activeCampaign.length + 6)).value = element.countryName
    sheet.getCell('I' + (index + data.activeCampaign.length + 6)).value = element.updatedAt
    sheet.getCell('J' + (index + data.activeCampaign.length + 6)).value = element.campaignStatus
    sheet.getCell('K' + (index + data.activeCampaign.length + 6)).value = element.pointStatus
  })

  sheet.columns.forEach(function (column, i) {
    let maxLength = 0
    if (column['eachCell']) {
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 13
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength + 3
    }
  })

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

export const approvePointCampaign = async (status: string, data: ICampaign[]): Promise<ExcelJS.Buffer> => {
  console.log(data)

  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Loyalty Hub Autocreate'
  workbook.lastModifiedBy = 'Loyalty Hub Autocreate'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet = workbook.addWorksheet('ApprovePointCampaign')

  sheet.getCell('A1').value = status
  sheet.getRow(1).height = 28.8
  sheet.getRow(1).alignment = { vertical: 'middle' }

  sheet.getCell('A2').value = 'Campaigns ID'
  sheet.getRow(2).height = 28.8
  sheet.getRow(2).alignment = { vertical: 'middle' }
  sheet.getCell('B2').value = 'Campaigns Name'
  sheet.getCell('C2').value = 'Target Type'
  sheet.getCell('D2').value = 'Target Type Value'
  sheet.getCell('E2').value = 'Tags Retailer'
  sheet.getCell('F2').value = 'Campaign Type'
  sheet.getCell('G2').value = 'Time line'
  sheet.getCell('H2').value = 'Country'
  sheet.getCell('I2').value = 'Last Update'
  sheet.getCell('J2').value = 'Status'
  sheet.getCell('K2').value = 'Point Approval'

  data.map((element, index) => {
    // BEWARE OF AA!
    sheet.getCell('A' + (index + 3)).value = element.campaignID
    sheet.getCell('B' + (index + 3)).value = element.campaignName
    sheet.getCell('C' + (index + 3)).value = element.campaignTargetType
    sheet.getCell('D' + (index + 3)).value = element.campaignTargetTypeValue
      ?.map((e) => e.targetTypeName + '  ')
      .toString()
    sheet.getCell('E' + (index + 3)).value = element.shopTagsList?.map((e) => e.tagName + '  ').toString()
    sheet.getCell('F' + (index + 3)).value = element.campaignType
    sheet.getCell('G' + (index + 3)).value =
      'Start :' + convertDate(element.restrictionStartDate) + '   End :' + convertDate(element.restrictionEndDate)
    sheet.getCell('H' + (index + 3)).value = element.countryName
    sheet.getCell('I' + (index + 3)).value = element.updatedAt
    sheet.getCell('J' + (index + 3)).value = element.campaignStatus
    sheet.getCell('K' + (index + 3)).value = element.pointStatus
  })

  sheet.columns.forEach(function (column, i) {
    let maxLength = 0
    if (column['eachCell']) {
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 13
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength + 3
    }
  })

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

interface IReviewCampaignCalculationPoint {
  adjustmentPoint: number
  phoneNumber: string
  point: number
  rawPoint: number
  saleVolume: number
  shopID: number
  shopName: string
  shopType: string
  updatedAt: string
  totalPoint?: number
  shopCode?: string
}

export const reviewPointStar = async (
  campaign: ICampaign,
  data: IReviewCampaignCalculationPoint[],
): Promise<ExcelJS.Buffer> => {
  console.log(data)

  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Loyalty Hub Autocreate'
  workbook.lastModifiedBy = 'Loyalty Hub Autocreate'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet = workbook.addWorksheet('ReviewPointStar')

  sheet.getCell('A1').value = 'Campaigns ID'
  sheet.getRow(1).height = 28.8
  sheet.getRow(1).alignment = { vertical: 'middle' }
  sheet.getCell('B1').value = 'Campaigns Name'
  sheet.getCell('C1').value = 'Target Type'
  sheet.getCell('D1').value = 'Target Type Value'
  sheet.getCell('E1').value = 'Tags Retailer'
  sheet.getCell('F1').value = 'Campaign Type'
  sheet.getCell('G1').value = 'Shop Type'
  sheet.getCell('H1').value = 'Time line'
  sheet.getCell('I1').value = 'Status'
  sheet.getCell('J1').value = 'Point Approval'
  sheet.getCell('K1').value = 'Remark Point'
  sheet.getCell('L1').value = 'Last Update'

  sheet.getCell('A2').value = campaign.campaignID
  sheet.getCell('B2').value = campaign.campaignName
  sheet.getCell('C2').value = campaign.campaignTargetType
  sheet.getCell('D2').value = campaign.campaignTargetTypeValue?.map((e) => e.targetTypeName + '  ').toString()
  sheet.getCell('E2').value = campaign.shopTagsList?.map((e) => e.tagName + '  ').toString()
  sheet.getCell('F2').value = campaign.campaignType
  sheet.getCell('G2').value = campaign.campaignShopType
  sheet.getCell('H2').value =
    'Start :' + convertDate(campaign.restrictionStartDate) + '   End :' + convertDate(campaign.restrictionEndDate)
  sheet.getCell('I2').value = campaign.campaignStatus
  sheet.getCell('J2').value = campaign.pointStatus
  sheet.getCell('K2').value = campaign.remarkPoint
  sheet.getCell('L2').value = campaign.updatedAt
  sheet.getRow(2).height = 28.8
  sheet.getRow(2).alignment = { vertical: 'middle' }

  sheet.getCell('A4').value = 'Customer Code'
  sheet.getCell('B4').value = 'Customer Type'
  sheet.getCell('C4').value = 'Customer Name'
  sheet.getCell('D4').value = 'Tel.'
  sheet.getCell('E4').value = 'Sale Volume'
  sheet.getCell('F4').value = 'Raw Point'
  sheet.getCell('G4').value = 'Adj. Point'
  sheet.getCell('H4').value = 'Acc. Point'
  sheet.getCell('I4').value = 'Total Point'
  sheet.getCell('J4').value = 'Last Update'
  sheet.getRow(4).height = 28.8
  sheet.getRow(4).alignment = { vertical: 'middle' }

  data.map((element, index) => {
    // BEWARE OF AA!
    sheet.getCell('A' + (index + 5)).value = element.shopCode
    sheet.getCell('B' + (index + 5)).value = element.shopType
    sheet.getCell('C' + (index + 5)).value = element.shopName
    sheet.getCell('D' + (index + 5)).value = element.phoneNumber
    sheet.getCell('E' + (index + 5)).value = element.saleVolume
    sheet.getCell('F' + (index + 5)).value = element.rawPoint
    sheet.getCell('G' + (index + 5)).value = element.adjustmentPoint
    sheet.getCell('H' + (index + 5)).value = element.point
    sheet.getCell('I' + (index + 5)).value = element.totalPoint
    sheet.getCell('J' + (index + 5)).value = element.updatedAt
  })

  sheet.columns.forEach(function (column, i) {
    let maxLength = 0
    if (column['eachCell']) {
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 13
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength + 3
    }
  })

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

export const approvePointStar = async (
  campaign: ICampaign,
  data: IReviewCampaignCalculationPoint[],
): Promise<ExcelJS.Buffer> => {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Loyalty Hub Autocreate'
  workbook.lastModifiedBy = 'Loyalty Hub Autocreate'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet = workbook.addWorksheet('ApprovePointStar')

  sheet.getCell('A1').value = 'Campaigns ID'
  sheet.getRow(1).height = 28.8
  sheet.getRow(1).alignment = { vertical: 'middle' }
  sheet.getCell('B1').value = 'Campaigns Name'
  sheet.getCell('C1').value = 'Target Type'
  sheet.getCell('D1').value = 'Target Type Value'
  sheet.getCell('E1').value = 'Tags Retailer'
  sheet.getCell('F1').value = 'Campaign Type'
  sheet.getCell('G1').value = 'Shop Type'
  sheet.getCell('H1').value = 'Time line'
  sheet.getCell('I1').value = 'Status'
  sheet.getCell('J1').value = 'Point Approval'
  sheet.getCell('K1').value = 'Remark Point'
  sheet.getCell('L1').value = 'Last Update'

  sheet.getCell('A2').value = campaign.campaignID
  sheet.getCell('B2').value = campaign.campaignName
  sheet.getCell('C2').value = campaign.campaignTargetType
  sheet.getCell('D2').value = campaign.campaignTargetTypeValue?.map((e) => e.targetTypeName + '  ').toString()
  sheet.getCell('E2').value = campaign.shopTagsList?.map((e) => e.tagName + '  ').toString()
  sheet.getCell('F2').value = campaign.campaignType
  sheet.getCell('G2').value = campaign.campaignShopType
  sheet.getCell('H2').value =
    'Start :' + convertDate(campaign.restrictionStartDate) + '   End :' + convertDate(campaign.restrictionEndDate)
  sheet.getCell('I2').value = campaign.campaignStatus
  sheet.getCell('J2').value = campaign.pointStatus
  sheet.getCell('K2').value = campaign.remarkPoint
  sheet.getCell('L2').value = campaign.updatedAt
  sheet.getRow(2).height = 28.8
  sheet.getRow(2).alignment = { vertical: 'middle' }

  sheet.getCell('A4').value = 'Customer Code'
  sheet.getCell('B4').value = 'Customer Type'
  sheet.getCell('C4').value = 'Customer Name'
  sheet.getCell('D4').value = 'Tel.'
  sheet.getCell('E4').value = 'Sale Volume'
  sheet.getCell('F4').value = 'Raw Point'
  sheet.getCell('G4').value = 'Adj. Point'
  sheet.getCell('H4').value = 'Acc. Point'
  sheet.getCell('I4').value = 'Total Point'
  sheet.getCell('J4').value = 'Last Update'
  sheet.getRow(4).height = 28.8
  sheet.getRow(4).alignment = { vertical: 'middle' }

  data.map((element, index) => {
    // BEWARE OF AA!
    sheet.getCell('A' + (index + 5)).value = element.shopCode
    sheet.getCell('B' + (index + 5)).value = element.shopType
    sheet.getCell('C' + (index + 5)).value = element.shopName
    sheet.getCell('D' + (index + 5)).value = element.phoneNumber
    sheet.getCell('E' + (index + 5)).value = element.saleVolume
    sheet.getCell('F' + (index + 5)).value = element.rawPoint
    sheet.getCell('G' + (index + 5)).value = element.adjustmentPoint
    sheet.getCell('H' + (index + 5)).value = element.point
    sheet.getCell('I' + (index + 5)).value = element.totalPoint
    sheet.getCell('J' + (index + 5)).value = element.updatedAt
  })

  sheet.columns.forEach(function (column, i) {
    let maxLength = 0
    if (column['eachCell']) {
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 13
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength + 3
    }
  })

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

interface ISendPoint {
  adjustmentPoint?: number | null
  amount?: number | null
  message?: string | null
  month?: number | null
  phoneNumber?: string | null
  point?: number | null
  rawPoint?: number | null
  requestID?: number | null
  requestTime?: string | null
  saleVolume?: number | null
  shopID?: number | null
  shopName?: string | null
  shopType?: string | null
  status?: string | null
  twilioTemplate?: string | null
  updatedAt?: string | null
  year?: number
}

export const requestUrBoxExcel = async (month: number, year: number, data: ISendPoint[]): Promise<ExcelJS.Buffer> => {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Loyalty Hub Autocreate'
  workbook.lastModifiedBy = 'Loyalty Hub Autocreate'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet = workbook.addWorksheet('RequestUrBox')
  sheet.getCell('A1').value = 'month'
  sheet.getCell('B1').value = 'year'
  sheet.getRow(1).height = 28.8
  sheet.getRow(1).alignment = { vertical: 'middle' }
  sheet.getCell('A2').value = month + 1
  sheet.getCell('B2').value = year
  sheet.getRow(2).height = 28.8
  sheet.getRow(2).alignment = { vertical: 'middle' }

  sheet.getCell('A4').value = 'requestID'
  sheet.getRow(4).height = 28.8
  sheet.getRow(4).alignment = { vertical: 'middle' }
  sheet.getCell('B4').value = 'requestTime'
  sheet.getCell('C4').value = 'customerID'
  sheet.getCell('D4').value = 'customerType'
  sheet.getCell('E4').value = 'customerName'
  sheet.getCell('F4').value = 'Tel.'
  sheet.getCell('G4').value = 'Sale Volume'
  sheet.getCell('H4').value = 'Raw Point'
  sheet.getCell('I4').value = 'Adj. Point'
  sheet.getCell('J4').value = 'Acc. Point'
  sheet.getCell('K4').value = 'Status'
  sheet.getCell('L4').value = 'Message'
  sheet.getCell('M4').value = 'Amount'
  sheet.getCell('N4').value = 'Last Update'
  sheet.getCell('O4').value = 'Twilio Template'

  data.map((element, index) => {
    // BEWARE OF AA!
    sheet.getCell('A' + (index + 5)).value = element.requestID
    sheet.getCell('B' + (index + 5)).value = element.requestTime
    sheet.getCell('C' + (index + 5)).value = element.shopID
    sheet.getCell('D' + (index + 5)).value = element.shopType
    sheet.getCell('E' + (index + 5)).value = element.shopName
    sheet.getCell('F' + (index + 5)).value = element.phoneNumber
    sheet.getCell('G' + (index + 5)).value = element.saleVolume
    sheet.getCell('H' + (index + 5)).value = element.rawPoint
    sheet.getCell('I' + (index + 5)).value = element.adjustmentPoint
    sheet.getCell('J' + (index + 5)).value = element.point
    sheet.getCell('K' + (index + 5)).value = element.status
    sheet.getCell('L' + (index + 5)).value = element.message
    sheet.getCell('M' + (index + 5)).value = element.amount
    sheet.getCell('N' + (index + 5)).value = element.updatedAt
    sheet.getCell('O' + (index + 5)).value = element.twilioTemplate
  })

  sheet.columns.forEach(function (column, i) {
    let maxLength = 0
    if (column['eachCell']) {
      column['eachCell']({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 13
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength + 3
    }
  })

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

export const checkIfAzureEmail = (email: string | undefined) => {
  let isSCG = false
  if (!email) return isSCG
  const allowMailDomains = ['scg.com']

  const mailDomain = email.split('@')[1]
  isSCG = allowMailDomains.includes(mailDomain)
  return isSCG
}

export const vietnamCurrencyFormat = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
