// Lib
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

// Images
import logoscgPNG from 'images/commons/iconScg.png'
import closeIconSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { menuApp, IMenuApp, env } from 'config'
import { useTranslation } from 'react-i18next'
import { MenuNavSide } from 'component'
import type { RootState } from 'states/store'

interface Props {
  sideNavOpen: boolean
  setSideNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const NavSideBar: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const getDecodeTokenReducer = useSelector((state: RootState) => state.decodeToken)

  if (!getDecodeTokenReducer.permission) return <CircularProgress />
  return (
    <div className={styles.navSideBar}>
      <div className={styles.sectionIcon} onClick={() => navigate('/')}>
        <img className={styles.iconScg} src={logoscgPNG} alt="" />
        <div className={styles.nameSection}>
          <h3 className="fontHeadOnlyNavSideBar">{t<string>('common.projectName')}</h3>
          <p className="fontOnlyNavSideBar">{t<string>('common.projectTitle')}</p>
          <p className="errorMsg fontBold">{` ${env.ENV_TEXT}`}</p>
        </div>
        {props.sideNavOpen ? (
          <img
            src={closeIconSVG}
            className={styles.closeIcon}
            alt="closeIconSVG"
            onClick={() => props.setSideNavOpen(false)}
          />
        ) : (
          ''
        )}
      </div>
      <div className={styles.wrappermenu}>
        {menuApp.map((data: IMenuApp, index) => {
          if (!data.isSubMenu) {
            if (!getDecodeTokenReducer.permission[data.accessKey as string]) return <></>

            return (
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : styles.notActive)}
                key={index}
                to={data.link}
                end
              >
                <div className={styles.box}>
                  <img className={styles.iconActive} src={data.photoActive} alt="" />
                  <img className={styles.iconNotActive} src={data.photo} alt="" />
                  <p className="fontOnlyNavSideBar">{data.name}</p>
                </div>
              </NavLink>
            )
          } else {
            return <MenuNavSide key={index} data={data} permission={getDecodeTokenReducer.permission} />
          }
        })}
      </div>
    </div>
  )
}

export default NavSideBar
