// Lib
import React from 'react'
import { Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

// Images
import SCGLogoPNG from 'images/commons/iconScg.png'

// Include in project
import styles from './index.module.scss'
import { Button, TextField, TextFieldPassword } from 'component'
import useForgotPassword from 'hooks/api/useForgotPassword'
import useLogin from 'hooks/api/uselogin'
import { env } from 'config'

const FormForgotPassword: React.FC = () => {
  const { values, errors, setFieldValue, handleSubmit } = useFormikContext<any>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { login } = useLogin((response) => {
    localStorage.setItem('accessToken', response.data.accessToken)
    localStorage.setItem('refreshToken', response.data.refreshToken)
    navigate('/')
  })

  const { sendOTP, verifyOTP, changePassword } = useForgotPassword(
    (responseSendOTP) => {
      setFieldValue('refID', responseSendOTP.data.refID)
      setFieldValue('stepNo', values.stepNo + 1)
    },
    (responseVerifyOTP) => {
      setFieldValue('stepNo', values.stepNo + 1)
    },
    (responseChangePassword) => {
      const packData = {
        email: values.email,
        password: values.newPassword,
      }
      login(packData)
    },
  )

  const handleSubmitForgotPassword = () => {
    if (!_.isEmpty(errors)) return

    if (values.stepNo === 0) {
      sendOTP({ email: values.email })
    } else if (values.stepNo === 1) {
      verifyOTP({ email: values.email, otpCode: values.optCode })
    } else if (values.stepNo === 2) {
      changePassword({ email: values.email, password: values.newPassword })
    }
  }

  return (
    <Form className={styles.form}>
      <div className={styles.header}>
        <img src={SCGLogoPNG} alt="SCGLogoPNG" />
        <div className={styles.wrapperText}>
          <h6>{t<string>('common.projectName')}</h6>
          <pre className="fontRegular">{t<string>('common.projectTitle')}</pre>
          <b className="errorMsg">{` ${env.ENV_TEXT}`}</b>
        </div>
      </div>
      <h6>Forgot Password</h6>
      <div className={styles.formInput}>
        {values.stepNo === 0 && <TextField label={t<string>('Login.email')} type={'text'} name="email" />}

        {values.stepNo === 1 && (
          <TextField label={`OTP (6 Digit) refID: ${values.refID}`} type={'text'} name="optCode" />
        )}

        {values.stepNo === 2 && (
          <>
            <TextFieldPassword label={`Password`} name="newPassword" />
            <TextFieldPassword label={`Re-Password`} name="reNewPassword" />
          </>
        )}
        <Button
          name={'Send OTP to Email'}
          typeButton={'submit'}
          type={'primary'}
          functionOnClick={() => {
            handleSubmit()
            handleSubmitForgotPassword()
          }}
          disabled={false}
        />

        <div className={styles.bottom}>
          <div></div>
          <pre
            className={styles.text}
            onClick={() => (values.stepNo === 0 ? navigate('/login') : setFieldValue('stepNo', values.stepNo - 1))}
          >
            {t<string>('common.back')}
          </pre>
        </div>
      </div>
    </Form>
  )
}
export default FormForgotPassword
