import { axiosInstance } from 'config/axios'

export function login({ email, password }: LoginParams): Promise<any> {
  return axiosInstance.post(
    `/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpAuthLogin`,
    {
      email,
      password,
    },
  )
}

export interface LoginParams {
  email: string
  password: string
}

export function refreshToken(refreshToken: string): Promise<any> {
  return axiosInstance.post(
    `/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpAuthRefreshToken`,
    {
      refreshToken: `${refreshToken}`,
    },
  )
}

export function sendOTP({ email }: { email: string }): Promise<any> {
  return axiosInstance.post(
    `/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpSendOtpForgetPassword`,
    {
      email,
    },
  )
}

export function verifyOTP({ email, otpCode }: { email: string; otpCode: string }): Promise<any> {
  return axiosInstance.post(
    `/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpVerifyOtpForgetPassword`,
    {
      email,
      otpCode,
    },
  )
}

export function changePassword({ email, password }: { email: string; password: string }): Promise<any> {
  return axiosInstance.post(
    `/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpChangePassword`,
    {
      email,
      password,
    },
  )
}

export interface ScgLoginParams {
  email: string
}

export function scgLogin({ email }: ScgLoginParams): Promise<any> {
  return axiosInstance.post(
    `/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpScgLogin`,
    {
      email,
    },
  )
}
