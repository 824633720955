// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'

//Images
import urBoxLogoPNG from 'images/sendPoint/urbox.png'
import excelSVG from 'images/sendPoint/excel.svg'
import pdfSVG from 'images/sendPoint/pdf.svg'

// Include in project
import styles from './index.module.scss'
import { Button, ChipByStatusUrBox, SelectSingle } from 'component'
import { useFilterYearMonth } from 'utils/other'
import { EUrBoxStatus } from 'utils/generated'

interface Props {
  status: string
  topUpThePoint: any
  handleExcelRequestUrBox: any
}

const HeadOfSendPoint: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { monthListOption, yearsListOption } = useFilterYearMonth()

  return (
    <section className={styles.container}>
      <img src={urBoxLogoPNG} alt="urBoxLogoPNG" width={134} height={38} />
      <div className={styles.formHeader}>
        <div className={styles.wrapperObject}>
          <div className={styles.inputForm}>
            <SelectSingle name={'filter.year'} option={yearsListOption} disabled={false} />
          </div>
          <div className={styles.inputForm}>
            <SelectSingle name={'filter.month'} option={monthListOption} disabled={false} />
          </div>
          <Button
            typeButton={'button'}
            type={'primary'}
            disabled={false}
            icon={excelSVG}
            functionOnClick={props.handleExcelRequestUrBox}
          />
          <Button typeButton={'button'} type={'primary'} disabled={false} icon={pdfSVG} />
        </div>
        <div className={styles.wrapperChipAndBtn}>
          <ChipByStatusUrBox status={props.status} />
          <Button
            typeButton={'button'}
            type={'primary'}
            disabled={props.status === EUrBoxStatus.Ready || props.status === EUrBoxStatus.Failed ? false : true}
            name={t<string>('common.send')}
            fullWidth
            functionOnClick={() => {
              props.topUpThePoint()
            }}
          />
        </div>
      </div>
    </section>
  )
}
export default HeadOfSendPoint
