// Lib
import React from 'react'
import { Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// Images
import SCGLogoPNG from 'images/commons/iconScg.png'

// Include in project
import styles from './index.module.scss'
import { Button, Checkbox, TextField, TextFieldPassword } from 'component'
import { env } from 'config'

const FormLogin: React.FC<{
  handleSubmitLogin: () => Promise<void>
  handleShowInputPassword: () => void
  isShowInputPassword: boolean
}> = ({ handleSubmitLogin, handleShowInputPassword, isShowInputPassword }) => {
  const { values, handleSubmit } = useFormikContext<{ email: string; password: string; remember: string[] }>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Form className={styles.formLogin}>
      <div className={styles.header}>
        <img src={SCGLogoPNG} alt="SCGLogoPNG" />
        <div className={styles.wrapperText}>
          <h6>{t<string>('common.projectName')}</h6>
          <pre className="fontRegular">
            {t<string>('common.projectTitle')}
            <b className="errorMsg">{` ${env.ENV_TEXT}`}</b>
          </pre>
          <pre className="fontRegular">
            <b>{isShowInputPassword ? t<string>('Login.title-non-ad-login') : t<string>('Login.title-ad-login')}</b>
          </pre>
        </div>
      </div>
      <div className={styles.formInput}>
        <TextField label={t<string>('Login.email')} type={'text'} name="email" />
        {isShowInputPassword && <TextFieldPassword label={t<string>('Login.password')} name="password" />}
        <Button
          name={t<string>('common.signIn')}
          typeButton={'submit'}
          type={'primary'}
          functionOnClick={async () => {
            handleSubmit()
            await handleSubmitLogin()
          }}
          disabled={false}
        />
        <div className={styles.bottom}>
          <Checkbox
            checked={values.remember.includes('remember')}
            value="remember"
            label={t<string>('Login.remember')}
            name="remember"
          />
          <pre className={styles.text} onClick={() => navigate('/forgot-password')}>
            {t<string>('Login.forgot')}
          </pre>
        </div>
        <div className={styles.footer}>
          <pre className={styles.text} onClick={() => handleShowInputPassword()}>
            {isShowInputPassword ? t<string>('Login.switch-ad-login') : t<string>('Login.switch-non-ad-login')}
          </pre>
        </div>
      </div>
    </Form>
  )
}
export default FormLogin
