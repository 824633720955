import { useMutation, useQuery } from '@apollo/client'
import useHandleResponseAPI from 'hooks/useHandleResponseAPI'
import { ADHocDATA, ADJUST_SALE_VOLUME, GET_DATA_FROM_DMS } from 'queries/callFromDMS.queries'

function useCallFromDMS(variables?: any) {
  const {
    data,
    refetch,
    loading,
    error: errorQuery,
  } = useQuery(GET_DATA_FROM_DMS, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [adjustSaleVolume, optionAdjust] = useMutation(ADJUST_SALE_VOLUME)
  const [adHocData, optionAdHoc] = useMutation(ADHocDATA)

  const isLoading = loading || optionAdjust.loading || optionAdHoc.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listDataSource?.res_code === '99')
      return JSON.stringify(errorQuery || data?.listDataSource?.res_desc)

    // optionAdjust
    if (optionAdjust?.error || optionAdjust?.data?.adjustSaleVolume?.res_code === '99')
      return JSON.stringify(optionAdjust?.error || optionAdjust?.data?.adjustSaleVolume?.res_desc)

    // optionAdHoc
    if (optionAdHoc?.error || optionAdHoc?.data?.adHocData?.res_code === '99')
      return JSON.stringify(optionAdHoc?.error || optionAdHoc?.data?.adHocData?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })
  return {
    data: data?.listDataSource,
    refetch,
    adjustSaleVolume,
    adHocData,
  }
}
export default useCallFromDMS
