import * as Yup from 'yup'

const yupForgotPassword = Yup.object().shape({
  email: Yup.string().required('Please enter your email'),
  optCode: Yup.string().when('stepNo', {
    is: 1,
    then: Yup.string().required('Please enter your otp'),
  }),
  newPassword: Yup.string().when('stepNo', {
    is: 2,
    then: Yup.string().required('Please enter your New Password'),
  }),
  reNewPassword: Yup.string().when('stepNo', {
    is: 2,
    then: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Password must match'),
  }),
})

export default yupForgotPassword
