// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Form, useFormikContext } from 'formik'
import _ from 'lodash'

// Images
import deleteSVG from 'images/commons/delete.svg'
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Button, TextField, SelectSingle, AutoComplete } from 'component'
import { setOpenDialog } from 'states/slice/modalDialog'
import { RootState } from 'states/store'
import { useHandleActionMasterData } from 'hooks'
import { ETransformationDataToServer } from 'utils/tranformData'

interface Props {
  openModal: boolean
  closeModal: () => void
  createShop: any
  updateShop: any
  deleteShop: any
  refetch: any
  refetchTrashList: any
}

const ModalOfShop: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, handleSubmit } = useFormikContext<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getProvinceReducer = useSelector((state: RootState) => state.province)
  const getSegmentReducer = useSelector((state: RootState) => state.segment)
  const getDistrictReducer = useSelector((state: RootState) => state.district)
  const getZoneReducer = useSelector((state: RootState) => state.zone)
  const getTagReducer = useSelector((state: RootState) => state.tags)

  const { handleUpdate, handleDelete } = useHandleActionMasterData({
    errors,
    values,
    createMasterData: props.createShop,
    updateMasterData: props.updateShop,
    typeUpdateMasterData: ETransformationDataToServer.UpdateShopRetailer,
    deleteMasterData: props.deleteShop,
    refetchMasterData: props.refetch,
    refetchMasterDataTrashList: props.refetchTrashList,
    closeModal: props.closeModal,
    key: 'retailerID',
  })

  return (
    <Modal open={props.openModal} onClose={props.closeModal}>
      <Form className={styles.containerModal}>
        <div className={styles.section}>
          <h3>{t('shopManagement.titleModol')}</h3>
          <img className={styles.closeIcon} src={closeSVG} alt="closeSVG" onClick={props.closeModal} />
        </div>

        <TextField name="formInput.retailerCode" type="text" label="Shop Code" require />
        <TextField name="formInput.retailerName" type="text" label="Shop Name" require />
        <SelectSingle
          name={'formInput.zoneID'}
          option={getZoneReducer.zoneListOption}
          disabled={false}
          label="Zone"
          require
        />
        <SelectSingle
          name={'formInput.provinceID'}
          option={getProvinceReducer.provinceListOption}
          disabled={false}
          label="Province"
          require
        />
        <SelectSingle
          name={'formInput.districtID'}
          option={getDistrictReducer.districtListOption}
          disabled={false}
          label="District"
          require
        />
        <SelectSingle
          name={'formInput.segmentID'}
          option={getSegmentReducer.segmentListOption}
          disabled={false}
          label="Segment"
          require
        />
        <TextField name={`formInput.phoneNumber`} type="text" label="Phone Number" require />
        <AutoComplete
          name={'formInput.tagList'}
          label="Tags"
          value={values.formInput.tagList}
          option={getTagReducer.tagsRetialerListOption}
        />

        <TextField name={`formInput.zaloID`} type="text" label="Zalo ID" />
        <TextField name={`formInput.email`} type="text" label="Email" />
        <div className={styles.rowButton}>
          {values.formInput.retailerID && (
            <div className={styles.button}>
              <Button
                typeButton="button"
                type={'outline'}
                disabled={false}
                name={t<string>('common.delete')}
                icon={deleteSVG}
                fullWidth
                functionOnClick={() =>
                  dispatch(
                    setOpenDialog({
                      title: t<string>('shopManagement.deleteTitle'),
                      description: t<string>('shopManagement.deleteDescription'),
                      status: 'danger',
                      functionOnSubmit: handleDelete,
                    }),
                  )
                }
              />
            </div>
          )}
          <div className={styles.button}>
            <Button
              typeButton="submit"
              type={'primary'}
              disabled={false}
              name={t<string>('common.save')}
              fullWidth
              functionOnClick={() => {
                handleSubmit()
                handleUpdate()
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalOfShop
