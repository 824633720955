export { menuApp } from './menu'
export type { IMenuApp, ISubMenuApp } from './menu'

//========================== [Vietnam] Starting Login Prod Key ============================

const VN_AZURE_CLIENT_ID_PRD = 'cc92db3b-9372-468e-a61a-0d9ce6ee8297'
const VN_AZURE_TENANT_ID_PRD = '5db8bf0e-8592-4ed0-82b2-a6d4d77933d4'

//========================== [Vietnam] Ending Login Prod Key ============================

//========================== [Vietnam] Starting Login Qas Key ============================

const VN_AZURE_CLIENT_ID_QAS = 'e5dbcbc0-93b3-467a-ba6d-028168090dd5'
const VN_AZURE_TENANT_ID_QAS = '5db8bf0e-8592-4ed0-82b2-a6d4d77933d4'

//========================== [Vietnam] Ending Login Qas Key ============================

interface CONFIG {
  ENV_TEXT: string
  AZURE_CLIENT_ID: string
  AZURE_TENANT_ID: string
}

interface IENV_CONFIG {
  prod: CONFIG
  dev: CONFIG
  test: CONFIG
}

const ENV_CONFIG: IENV_CONFIG = {
  prod: {
    ENV_TEXT: 'PRD-VN',
    AZURE_CLIENT_ID: VN_AZURE_CLIENT_ID_PRD,
    AZURE_TENANT_ID: VN_AZURE_TENANT_ID_PRD,
  },
  dev: {
    ENV_TEXT: 'QAS-VN',
    AZURE_CLIENT_ID: VN_AZURE_CLIENT_ID_QAS,
    AZURE_TENANT_ID: VN_AZURE_TENANT_ID_QAS,
  },
  test: {
    ENV_TEXT: 'TEST-VN',
    AZURE_CLIENT_ID: VN_AZURE_CLIENT_ID_QAS,
    AZURE_TENANT_ID: VN_AZURE_TENANT_ID_QAS,
  },
}

export const env = ENV_CONFIG[`${process.env['REACT_APP_END_POINT_STAGE'] as keyof IENV_CONFIG}`]
