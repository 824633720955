// Lib
import React from 'react'
import { withFormik } from 'formik'

// Images
import photoSidePNG from 'images/Login/loginNav.png'

// Include in project
import styles from './index.module.scss'
import { FormForgotPassword } from 'container'
import yupForgotPassword from 'validations/yupForgotPassword.validate'

const ForgotPassword: React.FC = () => {
  return (
    <div className={styles.container}>
      <section className={styles.wrapperInput}>
        <FormForgotPassword />
      </section>
      <section className={styles.wrapperImg}>
        <img src={photoSidePNG} alt="photoSidePNG" />
      </section>
    </div>
  )
}

const EnhancedForgotPassword = withFormik({
  mapPropsToValues: () => ({
    stepNo: 0,
    email: '',
    optCode: '',
    refID: '',
    newPassword: '',
    reNewPassword: '',
  }),
  validateOnMount: true,
  validationSchema: yupForgotPassword,
  handleSubmit: () => {
    console.log('SKIP')
  },
})(ForgotPassword)
export default EnhancedForgotPassword
