// Lib
import React, { useState } from 'react'
import { useFormikContext, withFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import yupDMS from 'validations/yupDMS.validate'
import { useSelector } from 'react-redux'

// Include in project
import { HeadOfCMS, ModalSaleVolumeAdjustment } from 'container'
import { Table } from 'component'
import Layout from 'layouts'
import styles from './index.module.scss'
import { useCallFromDMS, useModalForm, useInfiniteScroll } from 'hooks'
import {
  ETranformationDataIntoTable,
  ETransformationDataToServer,
  transformationDataIntoTable,
  transformationDataToServer,
} from 'utils/tranformData'
import { getDefaultMonthYear } from 'utils/other'
import { RootState } from 'states/store'

const CallFromDMS: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()
  const [reviewPoint, setReviewPoint] = useState(false)

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    dataSourceID: '',
    saleVolume: 0,
    adjustmentReason: '',
  })

  const {
    adHocData,
    data: resDataSourceList,
    adjustSaleVolume,
    refetch: refetchDataFromDMS,
  } = useCallFromDMS({
    pageSize: 25,
    nextToken: null,
    filter: {
      month: values.filter.month,
      year: values.filter.year,
    },
  })

  const { dataList } = useInfiniteScroll({
    result: resDataSourceList,
    refetch: refetchDataFromDMS,
    variables: {
      pageSize: 25,
      status: values.status,
      nextToken: resDataSourceList?.nextToken,
      filter: {
        month: values.filter.month,
        year: values.filter.year,
      },
    },
    filter: values.filter,
    key: 'listDataSource',
  })

  const dataSourceList = transformationDataIntoTable({
    dataList: dataList,
    type: ETranformationDataIntoTable.ListDataFromDMS,
    handleOpen,
  })

  const handleAdHocData = () => {
    const dataTranform = transformationDataToServer({
      type: ETransformationDataToServer.AdHocData,
      values: values.filter,
    })
    adHocData({ variables: dataTranform }).then((res: any) => {
      if (res.data.adHocData.res_code === '99') return

      refetchDataFromDMS()
      setReviewPoint(true)
    })
  }

  const shopRetailer = useSelector((state: RootState) => state.shop.retialerListOption)
  const shopBigRetailer = useSelector((state: RootState) => state.shopBigRetailer.bigRetialerListOption)

  const dataSourceListHighlight = dataSourceList.map((data: any) => {
    return {
      ...data,
      retailerName: data.retailerName ? (
        shopRetailer.find((ele: any) => ele.label === data.retailerName) ? (
          data.retailerName
        ) : (
          <div style={{ color: 'red' }}>{data.retailerName}</div>
        )
      ) : (
        ''
      ),
      retailerLevel1Name: data.retailerLevel1Name ? (
        shopBigRetailer.find((ele: any) => ele.label === data.retailerLevel1Name) ? (
          data.retailerLevel1Name
        ) : (
          <div style={{ color: 'red' }}>{data.retailerLevel1Name}</div>
        )
      ) : (
        ''
      ),
    }
  })
  return (
    <Layout open={false} section={t('callFromDMS.section')}>
      <div className={styles.container}>
        <HeadOfCMS reviewPointButtonStatus={reviewPoint} funtionOnclick={() => handleAdHocData()} />
        <Table
          objectKey={{
            inputDateFormat: 'Input date',
            orderCode: 'Order Code',
            dmsCode: 'DMS Code',
            code: 'Code',
            retailerName: 'Retailer Name',
            retailerLevel1Name: 'Retailer level 1 name',
            year: 'Year',
            month: 'Month',
            productBrand: 'Brand',
            productName: 'Product Name',
            saleVolume: 'Sale Volume',
            unitOrder: 'Order Status',
            shopType: 'Object',
            zone: 'Zone',
            province: 'Province',
            district: 'District',
            segment: 'Segment',
            phoneNumber: 'Phone Number',
            zaloID: 'Zalo ID',
            email: 'Email',
            actionView: 'Adjustment',
          }}
          data={dataSourceListHighlight || []}
        />

        <ModalSaleVolumeAdjustment
          onClose={handleClose}
          isOpen={isModalOpen}
          adjustSaleVolume={adjustSaleVolume}
          refetchDataFromDMS={refetchDataFromDMS}
        />
      </div>
    </Layout>
  )
}

const EnhancedCallFormDMS = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      dataSourceID: '',
      saleVolume: 0,
      adjustmentReason: '',
    },
    filter: {
      year: getDefaultMonthYear().defaultYear,
      month: getDefaultMonthYear().defaultMonth,
    },
  }),
  validationSchema: yupDMS,
  handleSubmit: () => {
    console.log('SKIP')
  },
})(CallFromDMS)
export default EnhancedCallFormDMS
