import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  shopBigRetialerList: [],
  bigRetialerListOption: [],
  refetch: null,
}

export const shopBigRetailerSlice = createSlice({
  name: 'shopBigRetailer',
  initialState,
  reducers: {
    setShopBigRetailer: (state, action: PayloadAction<any>) => {
      state.shopBigRetialerList = action.payload.items
      state.bigRetialerListOption = action.payload.items.map((data: any) => ({
        value: data.bigRetailerID,
        label: data.bigRetailerCode,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setShopBigRetailer } = shopBigRetailerSlice.actions

export default shopBigRetailerSlice.reducer
