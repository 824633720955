export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  JSON: any
}

export type AdHocRes = Response & {
  __typename?: 'AdHocRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type AdjustPoint = {
  __typename?: 'AdjustPoint'
  adjustmentReason?: Maybe<Scalars['String']>
  campaignCalculationID: Scalars['Int']
  createdAt?: Maybe<Scalars['Date']>
  createdBy?: Maybe<Scalars['String']>
  id: Scalars['Int']
  point?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['Date']>
}

export type AdjustSaleVolumeInput = {
  adjustmentReason: Scalars['String']
  dataSourceID: Scalars['Int']
  saleVolume: Scalars['Float']
}

export type AdjustSaleVolumeRes = Response & {
  __typename?: 'AdjustSaleVolumeRes'
  adjustmentReason?: Maybe<Scalars['String']>
  dataSourceID?: Maybe<Scalars['Int']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  saleVolume?: Maybe<Scalars['Float']>
}

export type ApproveCampaignCalculationPoint = {
  __typename?: 'ApproveCampaignCalculationPoint'
  adjustmentPoint?: Maybe<Scalars['Float']>
  campaignCalculationID: Scalars['Int']
  phoneNumber?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Float']>
  rawPoint?: Maybe<Scalars['Float']>
  saleVolume?: Maybe<Scalars['Float']>
  shopID: Scalars['Int']
  shopName: Scalars['String']
  shopType?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
}

export type ApproveCampaignInput = {
  campaignID: Scalars['String']
  campaignStatus: ECampaignStatus
  remark?: InputMaybe<Scalars['String']>
}

export type ApproveCampaignRes = Response & {
  __typename?: 'ApproveCampaignRes'
  campaignID?: Maybe<Scalars['String']>
  campaignStatus?: Maybe<ECampaignStatus>
  remark?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ApprovePointCampaignInput = {
  campaignID: Scalars['String']
  pointStatus: EPointStatus
  remarkPoint?: InputMaybe<Scalars['String']>
}

export type ApprovePointCampaignRes = Response & {
  __typename?: 'ApprovePointCampaignRes'
  campaignID?: Maybe<Scalars['String']>
  pointStatus?: Maybe<Scalars['String']>
  remarkPoint?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type BigRetailer = {
  __typename?: 'BigRetailer'
  addressID?: Maybe<Scalars['Int']>
  bigRetailerCode?: Maybe<Scalars['String']>
  bigRetailerID?: Maybe<Scalars['Int']>
  bigRetailerName?: Maybe<Scalars['String']>
  countryID?: Maybe<Scalars['Int']>
  districtID?: Maybe<Scalars['Int']>
  districtName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  ownRetailerID?: Maybe<Scalars['Int']>
  ownRetailerName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
  segmentID?: Maybe<Scalars['Int']>
  segmentName?: Maybe<Scalars['String']>
  sourceType?: Maybe<Scalars['String']>
  tagList?: Maybe<Array<Maybe<Tags>>>
  zaloID?: Maybe<Scalars['String']>
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type BigRetailerTag = {
  __typename?: 'BigRetailerTag'
  bigRetailerID?: Maybe<Scalars['Int']>
  tagID?: Maybe<Scalars['Int']>
}

export type Brand = {
  __typename?: 'Brand'
  brandName: Scalars['String']
  isActive: Scalars['Boolean']
  productBrandID: Scalars['Int']
}

export type BrandRes = Response & {
  __typename?: 'BrandRes'
  brandName?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  productBrandID?: Maybe<Scalars['Int']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ByZoneAchievedRtl = {
  __typename?: 'ByZoneAchievedRTL'
  achievedRTL?: Maybe<Scalars['Int']>
  notAchievedRTL?: Maybe<Scalars['Int']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
  totalRTL?: Maybe<Scalars['Int']>
}

export type ByZoneCostPerTon = {
  __typename?: 'ByZoneCostPerTon'
  costPerTon?: Maybe<Scalars['Int']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
}

export type ByZoneStar = {
  __typename?: 'ByZoneStar'
  basicStar?: Maybe<Scalars['Int']>
  bonusStar?: Maybe<Scalars['Int']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Int']>
}

export type Campaign = {
  __typename?: 'Campaign'
  campaignDescription?: Maybe<Scalars['String']>
  campaignGoal?: Maybe<Scalars['String']>
  campaignID?: Maybe<Scalars['ID']>
  campaignName?: Maybe<Scalars['String']>
  campaignShopType?: Maybe<ECampaignShopType>
  campaignStatus?: Maybe<ECampaignStatus>
  campaignTargetType?: Maybe<ECampaignTargetType>
  campaignTargetTypeValue?: Maybe<Array<Maybe<CampaignTargetTypeValueGet>>>
  campaignType?: Maybe<ECampaignType>
  countryID?: Maybe<Scalars['Int']>
  countryName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  documents?: Maybe<Scalars['JSON']>
  isSubRTLCal?: Maybe<Scalars['Boolean']>
  pointStatus?: Maybe<EPointStatus>
  productList?: Maybe<Array<Maybe<Product>>>
  productTagsList?: Maybe<Array<Maybe<Tags>>>
  promotion?: Maybe<Array<Maybe<Scalars['String']>>>
  provinceList?: Maybe<Array<Maybe<Province>>>
  remark?: Maybe<Scalars['String']>
  remarkPoint?: Maybe<Scalars['String']>
  restrictionEndDate?: Maybe<Scalars['Date']>
  restrictionStartDate?: Maybe<Scalars['Date']>
  rewardPointList?: Maybe<Array<Maybe<RewardPoint>>>
  shopTagsList?: Maybe<Array<Maybe<Tags>>>
  targetProductList?: Maybe<Array<Maybe<TargetProductList>>>
  updatedAt?: Maybe<Scalars['Date']>
  urBoxStatus?: Maybe<EUrBoxStatus>
}

export type CampaignCalculation = {
  __typename?: 'CampaignCalculation'
  adjustmentPoint?: Maybe<Scalars['Float']>
  campaignCalculationID: Scalars['Int']
  campaignID: Scalars['String']
  createdAt?: Maybe<Scalars['Date']>
  month?: Maybe<Scalars['Int']>
  point?: Maybe<Scalars['Float']>
  rawPoint?: Maybe<Scalars['Float']>
  saleVolume?: Maybe<Scalars['Float']>
  shopID: Scalars['Int']
  shopType?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
  year?: Maybe<Scalars['Int']>
}

export type CampaignCalculationPoint = {
  __typename?: 'CampaignCalculationPoint'
  adjustmentPoint?: Maybe<Scalars['Float']>
  phoneNumber?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Float']>
  rawPoint?: Maybe<Scalars['Float']>
  saleVolume?: Maybe<Scalars['Float']>
  shopID: Scalars['Int']
  shopName: Scalars['String']
  shopType?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
}

export type CampaignListRes = Response & {
  __typename?: 'CampaignListRes'
  items?: Maybe<Array<Maybe<Campaign>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type CampaignRes = Response & {
  __typename?: 'CampaignRes'
  campaignDescription?: Maybe<Scalars['String']>
  campaignGoal?: Maybe<Scalars['String']>
  campaignID?: Maybe<Scalars['ID']>
  campaignName?: Maybe<Scalars['String']>
  campaignShopType?: Maybe<ECampaignShopType>
  campaignStatus?: Maybe<ECampaignStatus>
  campaignTargetType?: Maybe<ECampaignTargetType>
  campaignTargetTypeValue?: Maybe<Array<Maybe<CampaignTargetTypeValueGet>>>
  campaignType?: Maybe<ECampaignType>
  countryID?: Maybe<Scalars['Int']>
  countryName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  documents?: Maybe<Scalars['JSON']>
  isSubRTLCal?: Maybe<Scalars['Boolean']>
  pointStatus?: Maybe<EPointStatus>
  productList?: Maybe<Array<Maybe<Product>>>
  productTagsList?: Maybe<Array<Maybe<Tags>>>
  provinceList?: Maybe<Array<Maybe<Province>>>
  remark?: Maybe<Scalars['String']>
  remarkPoint?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  restrictionEndDate?: Maybe<Scalars['Date']>
  restrictionStartDate?: Maybe<Scalars['Date']>
  rewardPointList?: Maybe<Array<Maybe<RewardPoint>>>
  shopTagsList?: Maybe<Array<Maybe<Tags>>>
  targetProductList?: Maybe<Array<Maybe<TargetProductList>>>
  updatedAt?: Maybe<Scalars['Date']>
  urBoxStatus?: Maybe<EUrBoxStatus>
}

export type CampaignTargetTypeValueInput = {
  targetTypeID: Scalars['Int']
  targetTypeName?: InputMaybe<Scalars['String']>
}

export type CampaignTransaction = {
  __typename?: 'CampaignTransaction'
  campaignCalculationID: Scalars['Int']
  campaignTransactionID: Scalars['Int']
  code?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  dmsCode?: Maybe<Scalars['String']>
  inputDate?: Maybe<Scalars['Date']>
  insertBy?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['Int']>
  orderCode?: Maybe<Scalars['String']>
  productBrand?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  retailerLevel1Name?: Maybe<Scalars['String']>
  retailerName?: Maybe<Scalars['String']>
  saleVolume?: Maybe<Scalars['Float']>
  segment?: Maybe<Scalars['String']>
  shopType?: Maybe<Scalars['String']>
  sourceType?: Maybe<ESourceType>
  unitOrder?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['Int']>
  zone?: Maybe<Scalars['String']>
}

export type Country = {
  __typename?: 'Country'
  countryID: Scalars['Int']
  countryName?: Maybe<Scalars['String']>
}

export type CountryRes = Response & {
  __typename?: 'CountryRes'
  countryID?: Maybe<Scalars['Int']>
  countryName?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type CreateBrandInput = {
  brandName: Scalars['String']
}

export type CreateCountryInput = {
  countryName?: InputMaybe<Scalars['String']>
}

export type CreateDistrictInput = {
  districtName: Scalars['String']
  provinceID: Scalars['Int']
}

export type CreateLevelProductInput = {
  level: Scalars['String']
  point: Scalars['Int']
}

export type CreateNormalCampaignInput = {
  campaignDescription?: InputMaybe<Scalars['String']>
  campaignGoal?: InputMaybe<Scalars['String']>
  campaignName: Scalars['String']
  documents?: InputMaybe<Scalars['JSON']>
  productList?: InputMaybe<Array<InputMaybe<CreateProductListLevelInput>>>
  restrictionEndDate: Scalars['Date']
  restrictionStartDate: Scalars['Date']
}

export type CreatePointAdjustmentInput = {
  adjustmentReason?: InputMaybe<Scalars['String']>
  campaignID: Scalars['String']
  month?: InputMaybe<Scalars['Int']>
  point: Scalars['Int']
  shopID: Scalars['Int']
  shopType: EShopType
  year?: InputMaybe<Scalars['Int']>
}

export type CreatePointAdjustmentRes = Response & {
  __typename?: 'CreatePointAdjustmentRes'
  adjustmentReason?: Maybe<Scalars['String']>
  campaignID: Scalars['String']
  month?: Maybe<Scalars['Int']>
  point: Scalars['Int']
  res_code: Scalars['String']
  res_desc: Scalars['String']
  shopID: Scalars['Int']
  shopType: EShopType
  year?: Maybe<Scalars['Int']>
}

export type CreatePointRewardInput = {
  level?: InputMaybe<Scalars['String']>
  productID?: InputMaybe<Scalars['Int']>
  target?: InputMaybe<Scalars['Int']>
}

export type CreateProductInput = {
  productBrandID: Scalars['Int']
  productName: Scalars['String']
  productUnit_id: Scalars['Int']
  tagList: Array<UpdateTagInput>
}

export type CreateProductListLevelInput = {
  levelList?: InputMaybe<Array<InputMaybe<CreateLevelProductInput>>>
  productID: Scalars['Int']
  productName?: InputMaybe<Scalars['String']>
  unitName?: InputMaybe<Scalars['String']>
}

export type CreateProvinceInput = {
  provinceCode: Scalars['String']
  provinceName: Scalars['String']
  zoneID: Scalars['Int']
}

export type CreateRewardPointInput = {
  pointReward: Scalars['JSON']
  targetTypeID: Scalars['Int']
}

export type CreateSegmentInput = {
  segmentName: Scalars['String']
}

export type CreateShopBigRetailerInput = {
  bigRetailerCode: Scalars['String']
  bigRetailerName: Scalars['String']
  districtID?: InputMaybe<Scalars['Int']>
  email?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
  ownRetailerID?: InputMaybe<Scalars['Int']>
  phoneNumber: Scalars['String']
  provinceID: Scalars['Int']
  segmentID: Scalars['Int']
  tagList?: InputMaybe<Array<InputMaybe<UpdateTagInput>>>
  zaloID?: InputMaybe<Scalars['String']>
  zoneID: Scalars['Int']
}

export type CreateShopRetailerInput = {
  districtID?: InputMaybe<Scalars['Int']>
  email?: InputMaybe<Scalars['String']>
  isActive: Scalars['Boolean']
  phoneNumber: Scalars['String']
  provinceID: Scalars['Int']
  retailerCode: Scalars['String']
  retailerName: Scalars['String']
  segmentID: Scalars['Int']
  tagList?: InputMaybe<Array<InputMaybe<UpdateTagInput>>>
  zaloID?: InputMaybe<Scalars['String']>
  zoneID: Scalars['Int']
}

export type CreateSpecialCampaignInput = {
  campaignDescription?: InputMaybe<Scalars['String']>
  campaignGoal?: InputMaybe<Scalars['String']>
  campaignName: Scalars['String']
  campaignShopType?: InputMaybe<ECampaignShopType>
  campaignTargetType?: InputMaybe<ECampaignTargetType>
  campaignTargetTypeValue?: InputMaybe<Array<InputMaybe<CampaignTargetTypeValueInput>>>
  campaignType: ECampaignType
  documents?: InputMaybe<Scalars['JSON']>
  isSubRTLCal: Scalars['Boolean']
  restrictionEndDate: Scalars['Date']
  restrictionStartDate: Scalars['Date']
  rewardPointList?: InputMaybe<Array<InputMaybe<CreateRewardPointInput>>>
  shopTagsList?: InputMaybe<Array<InputMaybe<UpdateTagInput>>>
  targetProductList?: InputMaybe<Array<InputMaybe<TargetProductListInput>>>
}

export type CreateTagInput = {
  tagColor: ETagColor
  tagName: Scalars['String']
  typeTag: ETagType
}

export type CreateUnitInput = {
  unitName: Scalars['String']
}

export type CreateUserInput = {
  email: Scalars['String']
  isActive: Scalars['Boolean']
  name: Scalars['String']
  password: Scalars['String']
  userRoleID: Scalars['Int']
}

export type CreateUserRoleInput = {
  countryID: Scalars['Int']
  permission: Scalars['JSON']
  userRole: EUserRole
}

export type CreateZoneInput = {
  zoneName: Scalars['String']
}

export type DataSource = {
  __typename?: 'DataSource'
  adjustmentByName?: Maybe<Scalars['String']>
  adjustmentByUserID?: Maybe<Scalars['Int']>
  adjustmentReason?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  countryID: Scalars['Int']
  dataSourceID: Scalars['Int']
  dataSourceStatus?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  dmsCode?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  inputDate?: Maybe<Scalars['Date']>
  month?: Maybe<Scalars['Int']>
  orderCode?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  productBrand?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  retailerLevel1Name?: Maybe<Scalars['String']>
  retailerName?: Maybe<Scalars['String']>
  saleVolume?: Maybe<Scalars['Float']>
  segment?: Maybe<Scalars['String']>
  shopType?: Maybe<Scalars['String']>
  sourceType: ESourceType
  unitOrder?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['Int']>
  zaloID?: Maybe<Scalars['String']>
  zone?: Maybe<Scalars['String']>
}

export type DeleteBrandInput = {
  ID: Scalars['Int']
}

export type DeleteBrandRes = Response & {
  __typename?: 'DeleteBrandRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type DeleteDistrictInput = {
  ID: Scalars['Int']
}

export type DeleteProductInput = {
  ID: Scalars['Int']
}

export type DeleteProductRes = Response & {
  __typename?: 'DeleteProductRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type DeleteProvinceInput = {
  ID: Scalars['Int']
}

export type DeleteSegmentInput = {
  ID: Scalars['Int']
}

export type DeleteShopBigRetailerInput = {
  ID: Scalars['Int']
}

export type DeleteShopRetailerInput = {
  ID: Scalars['Int']
}

export type DeleteTagInput = {
  ID: Scalars['Int']
}

export type DeleteTagRes = Response & {
  __typename?: 'DeleteTagRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type DeleteUnitInput = {
  ID: Scalars['Int']
}

export type DeleteUnitRes = Response & {
  __typename?: 'DeleteUnitRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type DeleteUserInput = {
  ID: Scalars['Int']
}

export type DeleteZoneInput = {
  ID: Scalars['Int']
}

export type DeleteZoneRes = Response & {
  __typename?: 'DeleteZoneRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type DetailApproveCampaignCalculationPointRes = Response & {
  __typename?: 'DetailApproveCampaignCalculationPointRes'
  campaignID?: Maybe<Scalars['String']>
  campaignName?: Maybe<Scalars['String']>
  customerName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Int']>
  pointTransaction?: Maybe<Array<Maybe<TPointTransaction>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  restrictionEndDate?: Maybe<Scalars['String']>
  restrictionStartDate?: Maybe<Scalars['String']>
  targetProductList?: Maybe<Array<Maybe<TTargetProductList>>>
}

export type DetailCampaignCalculationPointRes = Response & {
  __typename?: 'DetailCampaignCalculationPointRes'
  campaignID: Scalars['String']
  campaignName: Scalars['String']
  customerName: Scalars['String']
  phoneNumber: Scalars['String']
  point: Scalars['Int']
  pointTransaction?: Maybe<Array<Maybe<TPointTransaction>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  restrictionEndDate: Scalars['String']
  restrictionStartDate: Scalars['String']
  targetProductList?: Maybe<Array<Maybe<TTargetProductList>>>
}

export type District = {
  __typename?: 'District'
  countryID?: Maybe<Scalars['Int']>
  districtID?: Maybe<Scalars['Int']>
  districtName?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  provinceID?: Maybe<Scalars['Int']>
}

export type DistrictRes = Response & {
  __typename?: 'DistrictRes'
  districtID?: Maybe<Scalars['Int']>
  districtName?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  provinceID?: Maybe<Scalars['Int']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export enum ECampaignDetailType {
  Normal = 'NORMAL',
  Special = 'SPECIAL',
}

export enum ECampaignListStatus {
  Active = 'ACTIVE',
  All = 'ALL',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Rejected = 'REJECTED',
  WaitingApprove = 'WAITING_APPROVE',
}

export enum ECampaignListType {
  All = 'ALL',
  Normal = 'NORMAL',
  Special = 'SPECIAL',
}

export enum ECampaignShopType {
  All = 'ALL',
  BigRetailer = 'BIG_RETAILER',
  Retailer = 'RETAILER',
}

export enum ECampaignStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Rejected = 'REJECTED',
  WaitingApprove = 'WAITING_APPROVE',
}

export enum ECampaignTargetType {
  BigRetailer = 'BIG_RETAILER',
  Country = 'COUNTRY',
  District = 'DISTRICT',
  Province = 'PROVINCE',
  Retailer = 'RETAILER',
  Segment = 'SEGMENT',
  Zone = 'ZONE',
}

export enum ECampaignType {
  Normal = 'NORMAL',
  SpecialAchievedTargeting = 'SPECIAL_AchievedTargeting',
  SpecialMonthlyAccTargeting = 'SPECIAL_MonthlyAccTargeting',
  SpecialMonthlyEachTargeting = 'SPECIAL_MonthlyEachTargeting',
  SpecialPlusBonusPoint = 'SPECIAL_PlusBonusPoint',
  SpecialStepLevelTargeting = 'SPECIAL_StepLevelTargeting',
}

export enum EMonthlySaleVolumeType {
  BigRetailer = 'BIG_RETAILER',
  District = 'DISTRICT',
  Province = 'PROVINCE',
  Retailer = 'RETAILER',
  Segment = 'SEGMENT',
  Zone = 'ZONE',
}

export enum EMonthlyStarType {
  BigRetailer = 'BIG_RETAILER',
  Campaign = 'CAMPAIGN',
  District = 'DISTRICT',
  Province = 'PROVINCE',
  Retailer = 'RETAILER',
  Segment = 'SEGMENT',
  Zone = 'ZONE',
}

export enum EPermission {
  ApproveCampaign = 'ApproveCampaign',
  ApprovePoint = 'ApprovePoint',
  Brand = 'Brand',
  Calendar = 'Calendar',
  CallFromDms = 'CallFromDMS',
  District = 'District',
  NormalCampaign = 'NormalCampaign',
  Product = 'Product',
  Province = 'Province',
  ReviewPoint = 'ReviewPoint',
  Segment = 'Segment',
  SendPoint = 'SendPoint',
  ShopBigRetailer = 'ShopBigRetailer',
  SpecialCampaign = 'SpecialCampaign',
  Tag = 'Tag',
  Unit = 'Unit',
  UserList = 'UserList',
  UserRole = 'UserRole',
  Zone = 'Zone',
}

export enum EPointStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  WaitingApprove = 'WAITING_APPROVE',
  WaitingReview = 'WAITING_REVIEW',
}

export enum ESendPointStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Ready = 'READY',
  Success = 'SUCCESS',
}

export enum EShopType {
  BigRetailer = 'BIG_RETAILER',
  Retailer = 'RETAILER',
}

export enum ESourceType {
  Dms = 'DMS',
  PromptPlus = 'PROMPT_PLUS',
  SpecialAchievedTargeting = 'SPECIAL_AchievedTargeting',
  SpecialMonthlyAccTargeting = 'SPECIAL_MonthlyAccTargeting',
  SpecialMonthlyEachTargeting = 'SPECIAL_MonthlyEachTargeting',
  SpecialStepLevelTargeting = 'SPECIAL_StepLevelTargeting',
}

export enum ETagColor {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum ETagType {
  Product = 'PRODUCT',
  Retailer = 'RETAILER',
}

export enum EUrBoxStatus {
  Failed = 'FAILED',
  NoData = 'NO_DATA',
  Pending = 'PENDING',
  Ready = 'READY',
  Success = 'SUCCESS',
  WaitingReviewPoint = 'WAITING_REVIEW_POINT',
}

export enum EUserRole {
  Admin = 'ADMIN',
  Staff = 'STAFF',
  Supervisor = 'SUPERVISOR',
}

export type FilterDetailCampaignCalculationPointInput = {
  campaignID: Scalars['String']
  month?: InputMaybe<Scalars['Int']>
  shopID: Scalars['Int']
  shopType: EShopType
  year?: InputMaybe<Scalars['Int']>
}

export type FilterListBrandInput = {
  brandName?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type FilterListCampaignCalculationPointInput = {
  campaignID: Scalars['String']
  month?: InputMaybe<Scalars['Int']>
  year?: InputMaybe<Scalars['Int']>
}

export type FilterListCountryInput = {
  countryID?: InputMaybe<Scalars['Int']>
  countryName?: InputMaybe<Scalars['String']>
}

export type FilterListDataSourceInput = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type FilterListDistrictInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type FilterListProductInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type FilterListProvinceInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type FilterListSegmentInput = {
  isActive: Scalars['Boolean']
}

export type FilterListShopBigRetailerInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type FilterListShopRetailerInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type FilterListTagsInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
  tagName?: InputMaybe<Scalars['String']>
  typeTag?: InputMaybe<ETagType>
}

export type FilterListUnitInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
  unitName?: InputMaybe<Scalars['String']>
}

export type FilterListUserInput = {
  email?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  userRoleID?: InputMaybe<Scalars['Int']>
}

export type FilterListZoneInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
  zoneID?: InputMaybe<Scalars['Int']>
  zoneName?: InputMaybe<Scalars['String']>
}

export type ListApproveCampaignCalculationPointRes = Response & {
  __typename?: 'ListApproveCampaignCalculationPointRes'
  items?: Maybe<Array<Maybe<ApproveCampaignCalculationPoint>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListBrandRes = Response & {
  __typename?: 'ListBrandRes'
  items?: Maybe<Array<Maybe<Brand>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListByZoneAchievedRtl = Response & {
  __typename?: 'ListByZoneAchievedRTL'
  items?: Maybe<Array<Maybe<ByZoneAchievedRtl>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListByZoneCostPerTon = Response & {
  __typename?: 'ListByZoneCostPerTon'
  items?: Maybe<Array<Maybe<ByZoneCostPerTon>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListByZoneStar = Response & {
  __typename?: 'ListByZoneStar'
  items?: Maybe<Array<Maybe<ByZoneStar>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListCampaignCalculationPointRes = Response & {
  __typename?: 'ListCampaignCalculationPointRes'
  items?: Maybe<Array<Maybe<CampaignCalculationPoint>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListCountryRes = Response & {
  __typename?: 'ListCountryRes'
  items?: Maybe<Array<Maybe<Country>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListDataSourceRes = Response & {
  __typename?: 'ListDataSourceRes'
  items?: Maybe<Array<Maybe<DataSource>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListDistrictRes = Response & {
  __typename?: 'ListDistrictRes'
  items?: Maybe<Array<Maybe<District>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListMonthlyCostPerTon = Response & {
  __typename?: 'ListMonthlyCostPerTon'
  items?: Maybe<Array<Maybe<MonthlyCostPerTon>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListMonthlySaleVolume = Response & {
  __typename?: 'ListMonthlySaleVolume'
  items?: Maybe<Array<Maybe<MonthlySaleVolume>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListMonthlyStarRes = Response & {
  __typename?: 'ListMonthlyStarRes'
  items?: Maybe<Array<Maybe<MonthlyStar>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListNotificationByPermissionRes = Response & {
  __typename?: 'ListNotificationByPermissionRes'
  permissionList?: Maybe<Array<Maybe<EPermission>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListProductRes = Response & {
  __typename?: 'ListProductRes'
  items?: Maybe<Array<Maybe<Product>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListProvinceRes = Response & {
  __typename?: 'ListProvinceRes'
  items?: Maybe<Array<Maybe<Province>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListSegmentRes = Response & {
  __typename?: 'ListSegmentRes'
  items?: Maybe<Array<Maybe<Segment>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListShopBigRetailerRes = Response & {
  __typename?: 'ListShopBigRetailerRes'
  items?: Maybe<Array<Maybe<BigRetailer>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListShopRetailerRes = Response & {
  __typename?: 'ListShopRetailerRes'
  items?: Maybe<Array<Maybe<ShopRetailer>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListTagsRes = Response & {
  __typename?: 'ListTagsRes'
  items?: Maybe<Array<Maybe<Tags>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListUnitRes = Response & {
  __typename?: 'ListUnitRes'
  items?: Maybe<Array<Maybe<Unit>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListUserRes = Response & {
  __typename?: 'ListUserRes'
  items?: Maybe<Array<Maybe<User>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListUserRoleRes = Response & {
  __typename?: 'ListUserRoleRes'
  items?: Maybe<Array<Maybe<UserRole>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ListZoneRes = Response & {
  __typename?: 'ListZoneRes'
  items?: Maybe<Array<Maybe<Zone>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type MonthlyCostPerTon = {
  __typename?: 'MonthlyCostPerTon'
  costPerTon?: Maybe<Scalars['Int']>
  monthNumber?: Maybe<Scalars['Int']>
}

export type MonthlySaleVolume = {
  __typename?: 'MonthlySaleVolume'
  monthNumber?: Maybe<Scalars['Int']>
  saleVolume?: Maybe<Scalars['Int']>
}

export type MonthlyStar = {
  __typename?: 'MonthlyStar'
  basicStar?: Maybe<Scalars['Int']>
  bonusStar?: Maybe<Scalars['Int']>
  monthNumber?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
}

export type Mutation = {
  __typename?: 'Mutation'
  adHocData: AdHocRes
  adjustSaleVolume?: Maybe<AdjustSaleVolumeRes>
  approveCampaign?: Maybe<ApproveCampaignRes>
  approvePointCampaign: ApprovePointCampaignRes
  createBrand: BrandRes
  createCountry: CountryRes
  createDistrict: DistrictRes
  createNormalCampaign: CampaignRes
  createPointAdjustment?: Maybe<CreatePointAdjustmentRes>
  createProduct: ProductRes
  createProvince: ProvinceRes
  createSegment: SegmentRes
  createShopBigRetailer: ShopBigRetailerRes
  createShopRetailer: ShopRetailerRes
  createSpecialCampaign: CampaignRes
  createTags: TagsRes
  createUnit: UnitRes
  createUser?: Maybe<UserRes>
  createZone: ZoneRes
  deleteBrand: DeleteBrandRes
  deleteDistrict: RecoveryDistrictRes
  deleteProduct: DeleteProductRes
  deleteProvince: ProvinceRes
  deleteSegment: SegmentResDelete
  deleteShopBigRetailer: ShopBigRetailerRes
  deleteShopRetailer: ShopRetailerRes
  deleteTags: DeleteTagRes
  deleteUnit: DeleteUnitRes
  deleteUser?: Maybe<UserRes>
  deleteZone: DeleteZoneRes
  readNotification?: Maybe<ReadNotificationRes>
  recoveryBrand: RecoveryBrandRes
  recoveryDistrict: RecoveryDistrictRes
  recoveryProduct: RecoveryProductRes
  recoveryProvince: RecoveryProvinceRes
  recoverySegment: RecoverySegmentRes
  recoveryShopBigRetailer: RecoveryShopBigRetailerRes
  recoveryShopRetailer?: Maybe<RecoveryShopRetailerRes>
  recoveryTags: RecoveryTagsRes
  recoveryUnit: RecoveryUnitRes
  recoveryUser?: Maybe<RecoveryUserRes>
  recoveryZone: RecoveryZoneRes
  requestApproveCalculatePoint?: Maybe<RequestApproveCalculatePointRes>
  requestApproveCampaign: RequestApproveCampaignRes
  setBark?: Maybe<Scalars['String']>
  topUpThePoint: TopUpThePointRes
  updateBrand: BrandRes
  updateDistrict: DistrictRes
  updateNormalCampaign: MutationCampaignRes
  updateProduct: ProductRes
  updateProvince: ProvinceRes
  updateSegment: SegmentRes
  updateShopBigRetailer: ShopBigRetailerRes
  updateShopRetailer: ShopRetailerRes
  updateSpecialCampaign: MutationCampaignRes
  updateTags: TagsRes
  updateUnit: UnitRes
  updateUser?: Maybe<UserRes>
  updateUserPermission?: Maybe<ListUserRoleRes>
  updateZone: ZoneRes
}

export type MutationAdHocDataArgs = {
  input: QueryAdHocInput
}

export type MutationAdjustSaleVolumeArgs = {
  input: AdjustSaleVolumeInput
}

export type MutationApproveCampaignArgs = {
  input: ApproveCampaignInput
}

export type MutationApprovePointCampaignArgs = {
  input: ApprovePointCampaignInput
}

export type MutationCreateBrandArgs = {
  input: CreateBrandInput
}

export type MutationCreateCountryArgs = {
  input: CreateCountryInput
}

export type MutationCreateDistrictArgs = {
  input: CreateDistrictInput
}

export type MutationCreateNormalCampaignArgs = {
  input: CreateNormalCampaignInput
}

export type MutationCreatePointAdjustmentArgs = {
  input: CreatePointAdjustmentInput
}

export type MutationCreateProductArgs = {
  input: CreateProductInput
}

export type MutationCreateProvinceArgs = {
  input: CreateProvinceInput
}

export type MutationCreateSegmentArgs = {
  input: CreateSegmentInput
}

export type MutationCreateShopBigRetailerArgs = {
  input: CreateShopBigRetailerInput
}

export type MutationCreateShopRetailerArgs = {
  input: CreateShopRetailerInput
}

export type MutationCreateSpecialCampaignArgs = {
  input: CreateSpecialCampaignInput
}

export type MutationCreateTagsArgs = {
  input: CreateTagInput
}

export type MutationCreateUnitArgs = {
  input: CreateUnitInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationCreateZoneArgs = {
  input: CreateZoneInput
}

export type MutationDeleteBrandArgs = {
  input: DeleteBrandInput
}

export type MutationDeleteDistrictArgs = {
  input: DeleteDistrictInput
}

export type MutationDeleteProductArgs = {
  input: DeleteProductInput
}

export type MutationDeleteProvinceArgs = {
  input: DeleteProvinceInput
}

export type MutationDeleteSegmentArgs = {
  input: DeleteSegmentInput
}

export type MutationDeleteShopBigRetailerArgs = {
  input: DeleteShopBigRetailerInput
}

export type MutationDeleteShopRetailerArgs = {
  input: DeleteShopRetailerInput
}

export type MutationDeleteTagsArgs = {
  input: DeleteTagInput
}

export type MutationDeleteUnitArgs = {
  input: DeleteUnitInput
}

export type MutationDeleteUserArgs = {
  input: DeleteUserInput
}

export type MutationDeleteZoneArgs = {
  input?: InputMaybe<DeleteZoneInput>
}

export type MutationReadNotificationArgs = {
  input?: InputMaybe<ReadNotificationInput>
}

export type MutationRecoveryBrandArgs = {
  input: RecoveryBrandInput
}

export type MutationRecoveryDistrictArgs = {
  input: RecoveryDistrictInput
}

export type MutationRecoveryProductArgs = {
  input: RecoveryProductInput
}

export type MutationRecoveryProvinceArgs = {
  input: RecoveryProvinceInput
}

export type MutationRecoverySegmentArgs = {
  input: RecoverySegmentInput
}

export type MutationRecoveryShopBigRetailerArgs = {
  input: RecoveryShopBigRetailerInput
}

export type MutationRecoveryShopRetailerArgs = {
  input: RecoveryShopRetailerInput
}

export type MutationRecoveryTagsArgs = {
  input: RecoveryTagInput
}

export type MutationRecoveryUnitArgs = {
  input: RecoveryUnitInput
}

export type MutationRecoveryUserArgs = {
  input: RecoveryUserInput
}

export type MutationRecoveryZoneArgs = {
  input?: InputMaybe<RecoveryZoneInput>
}

export type MutationRequestApproveCalculatePointArgs = {
  input: RequestApproveCalculatePointInput
}

export type MutationRequestApproveCampaignArgs = {
  input: RequestApproveCampaignInput
}

export type MutationTopUpThePointArgs = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput
}

export type MutationUpdateDistrictArgs = {
  input: UpdateDistrictInput
}

export type MutationUpdateNormalCampaignArgs = {
  input: UpdateNormalCampaignInput
}

export type MutationUpdateProductArgs = {
  input: UpdateProductInput
}

export type MutationUpdateProvinceArgs = {
  input: UpdateProvinceInput
}

export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput
}

export type MutationUpdateShopBigRetailerArgs = {
  input: UpdateShopBigRetailerInput
}

export type MutationUpdateShopRetailerArgs = {
  input: UpdateShopRetailerInput
}

export type MutationUpdateSpecialCampaignArgs = {
  input: UpdateSpecialCampaignInput
}

export type MutationUpdateTagsArgs = {
  input: UpdateTagInput
}

export type MutationUpdateUnitArgs = {
  input: UpdateUnitInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserPermissionArgs = {
  input: UpdateUserPermissionInput
}

export type MutationUpdateZoneArgs = {
  input: UpdateZoneInput
}

export type MutationCampaignRes = Response & {
  __typename?: 'MutationCampaignRes'
  campaignID?: Maybe<Scalars['ID']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  createdAt?: Maybe<Scalars['Date']>
  isRead?: Maybe<Scalars['Boolean']>
  notificationDescription?: Maybe<Scalars['String']>
  notificationID?: Maybe<Scalars['Int']>
  permission?: Maybe<EPermission>
  receiverUserID?: Maybe<Scalars['Int']>
  senderUserID?: Maybe<Scalars['Int']>
  senderUserName?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
}

export type NotificationListRes = Response & {
  __typename?: 'NotificationListRes'
  items?: Maybe<Array<Maybe<Notification>>>
  nextToken?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type Product = {
  __typename?: 'Product'
  brandName?: Maybe<Scalars['String']>
  countryID?: Maybe<Scalars['Int']>
  isActive?: Maybe<Scalars['Boolean']>
  levelList?: Maybe<Array<Maybe<ProductLevel>>>
  productBrandID?: Maybe<Scalars['Int']>
  productID?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  productUnit_id?: Maybe<Scalars['Int']>
  tagList?: Maybe<Array<Maybe<Tags>>>
  unitName?: Maybe<Scalars['String']>
}

export type ProductLevel = {
  __typename?: 'ProductLevel'
  level?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Int']>
}

export type ProductRes = Response & {
  __typename?: 'ProductRes'
  brandName?: Maybe<Scalars['String']>
  countryID?: Maybe<Scalars['Int']>
  isActive?: Maybe<Scalars['Boolean']>
  productBrandID?: Maybe<Scalars['Int']>
  productID?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  productUnit_id?: Maybe<Scalars['Int']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  tagList?: Maybe<Array<Maybe<Tags>>>
  unitName?: Maybe<Scalars['String']>
}

export type Province = {
  __typename?: 'Province'
  countryID?: Maybe<Scalars['Int']>
  isActive?: Maybe<Scalars['Boolean']>
  provinceCode?: Maybe<Scalars['String']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type ProvinceRes = Response & {
  __typename?: 'ProvinceRes'
  countryID: Scalars['Int']
  isActive: Scalars['Boolean']
  provinceCode: Scalars['String']
  provinceID: Scalars['Int']
  provinceName: Scalars['String']
  res_code: Scalars['String']
  res_desc: Scalars['String']
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  approveCampaignCalculatePointList: ListApproveCampaignCalculationPointRes
  approvePointHistoryDetail: DetailApproveCampaignCalculationPointRes
  bark?: Maybe<Scalars['String']>
  campaignDetail: CampaignRes
  campaignsList: CampaignListRes
  healthCheck?: Maybe<Scalars['JSON']>
  listBrand: ListBrandRes
  listByZoneAchievedRTL: ListByZoneAchievedRtl
  listByZoneCostPerTon: ListByZoneCostPerTon
  listByZoneStar: ListByZoneStar
  listCountry: ListCountryRes
  listDataSource: ListDataSourceRes
  listDistrict: ListDistrictRes
  listMonthlyCostPerTon: ListMonthlyCostPerTon
  listMonthlySaleVolume: ListMonthlySaleVolume
  listMonthlyStar: ListMonthlyStarRes
  listNotification: NotificationListRes
  listNotificationByPermission: ListNotificationByPermissionRes
  listProduct: ListProductRes
  listProvince: ListProvinceRes
  listSegment: ListSegmentRes
  listShopBigRetailer: ListShopBigRetailerRes
  listShopRetailer: ListShopRetailerRes
  listTags: ListTagsRes
  listUnit: ListUnitRes
  listUser: ListUserRes
  listZone: ListZoneRes
  productList: ListProductRes
  provinceList: ListProvinceRes
  reviewCampaignCalculationPoint: ListCampaignCalculationPointRes
  reviewPointHistoryDetail: DetailCampaignCalculationPointRes
  sendPointList: SendPointListRes
  shopList: ShopListRes
  tagList: TagListRes
  userPermission: ListUserRoleRes
}

export type QueryApproveCampaignCalculatePointListArgs = {
  filter?: InputMaybe<FilterListCampaignCalculationPointInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryApprovePointHistoryDetailArgs = {
  campaignCalculationID: Scalars['Int']
  campaignID: Scalars['String']
  month?: InputMaybe<Scalars['Int']>
  shopID: Scalars['Int']
  shopType: EShopType
  year?: InputMaybe<Scalars['Int']>
}

export type QueryCampaignDetailArgs = {
  campaignID: Scalars['ID']
  type: ECampaignDetailType
}

export type QueryCampaignsListArgs = {
  month?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
  status: ECampaignListStatus
  targetType?: InputMaybe<ECampaignTargetType>
  type: ECampaignListType
  year?: InputMaybe<Scalars['Int']>
}

export type QueryListBrandArgs = {
  filter?: InputMaybe<FilterListBrandInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListByZoneAchievedRtlArgs = {
  endDate?: InputMaybe<Scalars['Date']>
  startDate?: InputMaybe<Scalars['Date']>
  zoneID?: InputMaybe<Scalars['Int']>
}

export type QueryListByZoneCostPerTonArgs = {
  endDate?: InputMaybe<Scalars['Date']>
  startDate?: InputMaybe<Scalars['Date']>
  zoneID?: InputMaybe<Scalars['Int']>
}

export type QueryListByZoneStarArgs = {
  endDate?: InputMaybe<Scalars['Date']>
  startDate?: InputMaybe<Scalars['Date']>
  zoneID?: InputMaybe<Scalars['Int']>
}

export type QueryListCountryArgs = {
  filter?: InputMaybe<FilterListCountryInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListDataSourceArgs = {
  filter?: InputMaybe<FilterListDataSourceInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListDistrictArgs = {
  filter?: InputMaybe<FilterListDistrictInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListMonthlyCostPerTonArgs = {
  type: EMonthlySaleVolumeType
  value: Scalars['String']
  year: Scalars['Int']
}

export type QueryListMonthlySaleVolumeArgs = {
  type: EMonthlySaleVolumeType
  value: Scalars['String']
  year: Scalars['Int']
}

export type QueryListMonthlyStarArgs = {
  type: EMonthlyStarType
  value: Scalars['String']
  year: Scalars['Int']
}

export type QueryListNotificationArgs = {
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListProductArgs = {
  filter?: InputMaybe<FilterListProductInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListProvinceArgs = {
  filter?: InputMaybe<FilterListProvinceInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListSegmentArgs = {
  filter?: InputMaybe<FilterListSegmentInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListShopBigRetailerArgs = {
  filter?: InputMaybe<FilterListShopBigRetailerInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListShopRetailerArgs = {
  filter?: InputMaybe<FilterListShopRetailerInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListTagsArgs = {
  filter?: InputMaybe<FilterListTagsInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListUnitArgs = {
  filter?: InputMaybe<FilterListUnitInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListUserArgs = {
  filter?: InputMaybe<FilterListUserInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryListZoneArgs = {
  filter?: InputMaybe<FilterListZoneInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryProductListArgs = {
  tagID?: InputMaybe<Scalars['Int']>
}

export type QueryReviewCampaignCalculationPointArgs = {
  filter?: InputMaybe<FilterListCampaignCalculationPointInput>
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryReviewPointHistoryDetailArgs = {
  campaignID: Scalars['String']
  month?: InputMaybe<Scalars['Int']>
  shopID: Scalars['Int']
  shopType: EShopType
  year?: InputMaybe<Scalars['Int']>
}

export type QuerySendPointListArgs = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type QueryShopListArgs = {
  provinceID?: InputMaybe<Scalars['Int']>
  shopType?: InputMaybe<Scalars['String']>
  tagID?: InputMaybe<Scalars['Int']>
}

export type QueryTagListArgs = {
  type: ETagType
}

export type QueryUserPermissionArgs = {
  nextToken?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
}

export type QueryAdHocInput = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type ReadNotificationInput = {
  notificationID?: InputMaybe<Scalars['Int']>
  permission?: InputMaybe<EPermission>
}

export type ReadNotificationRes = Response & {
  __typename?: 'ReadNotificationRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryBrandInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryBrandRes = Response & {
  __typename?: 'RecoveryBrandRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryDistrictInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryDistrictRes = Response & {
  __typename?: 'RecoveryDistrictRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryProductInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryProductRes = Response & {
  __typename?: 'RecoveryProductRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryProvinceInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryProvinceRes = Response & {
  __typename?: 'RecoveryProvinceRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoverySegmentInput = {
  listID: Array<Scalars['Int']>
}

export type RecoverySegmentRes = Response & {
  __typename?: 'RecoverySegmentRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryShopBigRetailerInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryShopBigRetailerRes = Response & {
  __typename?: 'RecoveryShopBigRetailerRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryShopRetailerInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryShopRetailerRes = Response & {
  __typename?: 'RecoveryShopRetailerRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryTagInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryTagsRes = Response & {
  __typename?: 'RecoveryTagsRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryUnitInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryUnitRes = Response & {
  __typename?: 'RecoveryUnitRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryUserInput = {
  listID?: InputMaybe<Array<Scalars['Int']>>
}

export type RecoveryUserRes = {
  __typename?: 'RecoveryUserRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RecoveryZoneInput = {
  listID: Array<Scalars['Int']>
}

export type RecoveryZoneRes = Response & {
  __typename?: 'RecoveryZoneRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RequestApproveCalculatePointInput = {
  campaignID: Scalars['String']
  month?: InputMaybe<Scalars['Int']>
  year?: InputMaybe<Scalars['Int']>
}

export type RequestApproveCalculatePointRes = Response & {
  __typename?: 'RequestApproveCalculatePointRes'
  pointStatus?: Maybe<EPointStatus>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RequestApproveCampaignInput = {
  id: Scalars['String']
}

export type RequestApproveCampaignRes = Response & {
  __typename?: 'RequestApproveCampaignRes'
  campaignID?: Maybe<Scalars['String']>
  campaignStatus?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type Response = {
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type RetailerTag = {
  __typename?: 'RetailerTag'
  retailerID?: Maybe<Scalars['Int']>
  tagID?: Maybe<Scalars['Int']>
}

export type RewardPoint = {
  __typename?: 'RewardPoint'
  campaignID?: Maybe<Scalars['ID']>
  pointReward?: Maybe<Scalars['JSON']>
  rewardPointID?: Maybe<Scalars['Int']>
  targetTypeID?: Maybe<Scalars['Int']>
  targetTypeName?: Maybe<Scalars['String']>
}

export type Segment = {
  __typename?: 'Segment'
  isActive?: Maybe<Scalars['Boolean']>
  segmentID?: Maybe<Scalars['Int']>
  segmentName?: Maybe<Scalars['String']>
}

export type SegmentRes = Response & {
  __typename?: 'SegmentRes'
  isActive?: Maybe<Scalars['Boolean']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  segmentID?: Maybe<Scalars['Int']>
  segmentName?: Maybe<Scalars['String']>
}

export type SegmentResDelete = Response & {
  __typename?: 'SegmentResDelete'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type SendPoint = {
  __typename?: 'SendPoint'
  adjustmentPoint?: Maybe<Scalars['Float']>
  amount?: Maybe<Scalars['Float']>
  message?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Float']>
  rawPoint?: Maybe<Scalars['Float']>
  requestID?: Maybe<Scalars['Int']>
  requestTime?: Maybe<Scalars['Date']>
  saleVolume?: Maybe<Scalars['Float']>
  shopID?: Maybe<Scalars['Int']>
  shopName?: Maybe<Scalars['String']>
  shopType?: Maybe<Scalars['String']>
  status?: Maybe<ESendPointStatus>
  updatedAt?: Maybe<Scalars['Date']>
}

export type SendPointListRes = Response & {
  __typename?: 'SendPointListRes'
  items?: Maybe<Array<Maybe<SendPoint>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  urBoxStatus?: Maybe<EUrBoxStatus>
}

export type ShopBigRetailerRes = Response & {
  __typename?: 'ShopBigRetailerRes'
  addressID?: Maybe<Scalars['Int']>
  bigRetailerCode?: Maybe<Scalars['String']>
  bigRetailerID?: Maybe<Scalars['Int']>
  bigRetailerName?: Maybe<Scalars['String']>
  countryID?: Maybe<Scalars['Int']>
  districtID?: Maybe<Scalars['Int']>
  districtName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  ownRetailerID?: Maybe<Scalars['Int']>
  ownRetailerName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  segmentID?: Maybe<Scalars['Int']>
  segmentName?: Maybe<Scalars['String']>
  sourceType?: Maybe<Scalars['String']>
  tagList?: Maybe<Array<Maybe<Tags>>>
  zaloID?: Maybe<Scalars['String']>
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type ShopListRes = Response & {
  __typename?: 'ShopListRes'
  items?: Maybe<Array<Maybe<ShopRetailer>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type ShopRetailer = {
  __typename?: 'ShopRetailer'
  addressID?: Maybe<Scalars['Int']>
  countryID?: Maybe<Scalars['Int']>
  districtID?: Maybe<Scalars['Int']>
  districtName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  phoneNumber?: Maybe<Scalars['String']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
  retailerCode?: Maybe<Scalars['String']>
  retailerID?: Maybe<Scalars['Int']>
  retailerName?: Maybe<Scalars['String']>
  segmentID?: Maybe<Scalars['Int']>
  segmentName?: Maybe<Scalars['String']>
  sourceType?: Maybe<Scalars['String']>
  tagList?: Maybe<Array<Maybe<Tags>>>
  zaloID?: Maybe<Scalars['String']>
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type ShopRetailerRes = Response & {
  __typename?: 'ShopRetailerRes'
  addressID?: Maybe<Scalars['Int']>
  countryID?: Maybe<Scalars['Int']>
  districtID?: Maybe<Scalars['Int']>
  districtName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  phoneNumber?: Maybe<Scalars['String']>
  provinceID?: Maybe<Scalars['Int']>
  provinceName?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  retailerCode?: Maybe<Scalars['String']>
  retailerID?: Maybe<Scalars['Int']>
  retailerName?: Maybe<Scalars['String']>
  segmentID?: Maybe<Scalars['Int']>
  segmentName?: Maybe<Scalars['String']>
  sourceType?: Maybe<Scalars['String']>
  tagList?: Maybe<Array<Maybe<Tags>>>
  zaloID?: Maybe<Scalars['String']>
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type SyncRetailerRes = Response & {
  __typename?: 'SyncRetailerRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type TLevelList = {
  __typename?: 'TLevelList'
  actualPoint?: Maybe<Scalars['Int']>
  actualTarget?: Maybe<Scalars['Int']>
  level?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Int']>
  target?: Maybe<Scalars['Int']>
}

export type TPointTransaction = {
  __typename?: 'TPointTransaction'
  inputDate?: Maybe<Scalars['Date']>
  insertBy?: Maybe<Scalars['String']>
  orderCode?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  saleVolume?: Maybe<Scalars['Int']>
  targetProduct?: Maybe<Scalars['String']>
  unitName?: Maybe<Scalars['String']>
}

export type TTargetProductList = {
  __typename?: 'TTargetProductList'
  levelList?: Maybe<Array<Maybe<TLevelList>>>
  productID?: Maybe<Scalars['Int']>
  productName?: Maybe<Scalars['String']>
  tagID?: Maybe<Scalars['Int']>
  tagName?: Maybe<Scalars['String']>
}

export type TagListRes = Response & {
  __typename?: 'TagListRes'
  items?: Maybe<Array<Maybe<Tags>>>
  res_code: Scalars['String']
  res_desc: Scalars['String']
}

export type Tags = {
  __typename?: 'Tags'
  isActive: Scalars['Boolean']
  tagColor: ETagColor
  tagID: Scalars['Int']
  tagName: Scalars['String']
  typeTag: ETagType
}

export type TagsRes = Response & {
  __typename?: 'TagsRes'
  isActive?: Maybe<Scalars['Boolean']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  tagColor?: Maybe<ETagColor>
  tagID?: Maybe<Scalars['Int']>
  tagName?: Maybe<Scalars['String']>
  typeTag?: Maybe<ETagType>
}

export type TargetProductList = {
  __typename?: 'TargetProductList'
  levelList?: Maybe<Array<Maybe<LevelProduct>>>
  tagID?: Maybe<Scalars['Int']>
  tagName?: Maybe<Scalars['String']>
}

export type TargetProductListInput = {
  levelList: Array<InputMaybe<CreateLevelProductInput>>
  tagID: Scalars['Int']
}

export type TopUpThePointRes = Response & {
  __typename?: 'TopUpThePointRes'
  res_code: Scalars['String']
  res_desc: Scalars['String']
  urBoxStatus?: Maybe<EUrBoxStatus>
}

export type Unit = {
  __typename?: 'Unit'
  id: Scalars['Int']
  isActive: Scalars['Boolean']
  unitName: Scalars['String']
}

export type UnitRes = Response & {
  __typename?: 'UnitRes'
  id?: Maybe<Scalars['Int']>
  isActive?: Maybe<Scalars['Boolean']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  unitName?: Maybe<Scalars['String']>
}

export type UpdateBrandInput = {
  brandName?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
  productBrandID: Scalars['Int']
}

export type UpdateDistrictInput = {
  countryID?: InputMaybe<Scalars['Int']>
  districtID: Scalars['Int']
  districtName?: InputMaybe<Scalars['String']>
  provinceID?: InputMaybe<Scalars['Int']>
}

export type UpdateNormalCampaignInput = {
  campaignDescription?: InputMaybe<Scalars['String']>
  campaignGoal?: InputMaybe<Scalars['String']>
  campaignID: Scalars['String']
  campaignName: Scalars['String']
  documents?: InputMaybe<Scalars['JSON']>
  productList?: InputMaybe<Array<InputMaybe<CreateProductListLevelInput>>>
  restrictionEndDate: Scalars['Date']
  restrictionStartDate: Scalars['Date']
}

export type UpdateProductInput = {
  countryID?: InputMaybe<Scalars['Int']>
  productBrandID?: InputMaybe<Scalars['Int']>
  productID: Scalars['Int']
  productName?: InputMaybe<Scalars['String']>
  productUnit_id?: InputMaybe<Scalars['Int']>
  tagList?: InputMaybe<Array<UpdateTagInput>>
}

export type UpdateProvinceInput = {
  countryID?: InputMaybe<Scalars['Int']>
  provinceCode?: InputMaybe<Scalars['String']>
  provinceID: Scalars['Int']
  provinceName?: InputMaybe<Scalars['String']>
  zoneID?: InputMaybe<Scalars['Int']>
}

export type UpdateSegmentInput = {
  segmentID: Scalars['Int']
  segmentName?: InputMaybe<Scalars['String']>
}

export type UpdateShopBigRetailerInput = {
  addressID: Scalars['Int']
  bigRetailerCode?: InputMaybe<Scalars['String']>
  bigRetailerID: Scalars['Int']
  bigRetailerName?: InputMaybe<Scalars['String']>
  countryID?: InputMaybe<Scalars['Int']>
  districtID?: InputMaybe<Scalars['Int']>
  districtName?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
  ownRetailerID?: InputMaybe<Scalars['Int']>
  ownRetailerName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  provinceID?: InputMaybe<Scalars['Int']>
  provinceName?: InputMaybe<Scalars['String']>
  segmentID?: InputMaybe<Scalars['Int']>
  segmentName?: InputMaybe<Scalars['String']>
  sourceType?: InputMaybe<Scalars['String']>
  tagList?: InputMaybe<Array<InputMaybe<UpdateTagInput>>>
  zaloID?: InputMaybe<Scalars['String']>
  zoneID?: InputMaybe<Scalars['Int']>
  zoneName?: InputMaybe<Scalars['String']>
}

export type UpdateShopRetailerInput = {
  addressID: Scalars['Int']
  countryID?: InputMaybe<Scalars['Int']>
  districtID?: InputMaybe<Scalars['Int']>
  districtName?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
  phoneNumber?: InputMaybe<Scalars['String']>
  provinceID?: InputMaybe<Scalars['Int']>
  provinceName?: InputMaybe<Scalars['String']>
  retailerCode?: InputMaybe<Scalars['String']>
  retailerID: Scalars['Int']
  retailerName?: InputMaybe<Scalars['String']>
  segmentID?: InputMaybe<Scalars['Int']>
  segmentName?: InputMaybe<Scalars['String']>
  sourceType?: InputMaybe<Scalars['String']>
  tagList?: InputMaybe<Array<InputMaybe<UpdateTagInput>>>
  zaloID?: InputMaybe<Scalars['String']>
  zoneID?: InputMaybe<Scalars['Int']>
  zoneName?: InputMaybe<Scalars['String']>
}

export type UpdateSpecialCampaignInput = {
  campaignDescription?: InputMaybe<Scalars['String']>
  campaignGoal?: InputMaybe<Scalars['String']>
  campaignID: Scalars['String']
  campaignName: Scalars['String']
  campaignShopType?: InputMaybe<ECampaignShopType>
  campaignTargetType?: InputMaybe<ECampaignTargetType>
  campaignTargetTypeValue?: InputMaybe<Array<InputMaybe<CampaignTargetTypeValueInput>>>
  campaignType: ECampaignType
  documents?: InputMaybe<Scalars['JSON']>
  isSubRTLCal: Scalars['Boolean']
  restrictionEndDate: Scalars['Date']
  restrictionStartDate: Scalars['Date']
  rewardPointList?: InputMaybe<Array<InputMaybe<CreateRewardPointInput>>>
  shopTagsList?: InputMaybe<Array<InputMaybe<UpdateTagInput>>>
  targetProductList?: InputMaybe<Array<InputMaybe<TargetProductListInput>>>
}

export type UpdateTagInput = {
  countryID?: InputMaybe<Scalars['Int']>
  tagColor?: InputMaybe<ETagColor>
  tagID: Scalars['Int']
  tagName?: InputMaybe<Scalars['String']>
  typeTag?: InputMaybe<ETagType>
}

export type UpdateUnitInput = {
  id: Scalars['Int']
  isActive?: InputMaybe<Scalars['Boolean']>
  unitName?: InputMaybe<Scalars['String']>
}

export type UpdateUserInput = {
  countryID?: InputMaybe<Scalars['Int']>
  email?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  userID: Scalars['Int']
  userRoleID?: InputMaybe<Scalars['Int']>
}

export type UpdateUserPermissionInput = {
  items?: InputMaybe<Array<InputMaybe<UserPermissionInput>>>
}

export type UpdateZoneInput = {
  countryID?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  zoneID: Scalars['Int']
  zoneName?: InputMaybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  countryID?: Maybe<Scalars['Int']>
  countryName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
  userID?: Maybe<Scalars['Int']>
  userRole?: Maybe<EUserRole>
  userRoleID?: Maybe<Scalars['Int']>
}

export type UserOtp = {
  __typename?: 'UserOtp'
  createdAt?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  isUsed?: Maybe<Scalars['Boolean']>
  isVerify?: Maybe<Scalars['Boolean']>
  otpCode?: Maybe<Scalars['String']>
  otpID?: Maybe<Scalars['Int']>
  refID?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
}

export type UserPermissionInput = {
  countryID: Scalars['Int']
  isActive?: InputMaybe<Scalars['Boolean']>
  permission?: InputMaybe<Scalars['JSON']>
  userRole: EUserRole
  userRoleID: Scalars['Int']
}

export type UserRes = Response & {
  __typename?: 'UserRes'
  countryID?: Maybe<Scalars['Int']>
  countryName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  updatedAt?: Maybe<Scalars['Date']>
  userID?: Maybe<Scalars['Int']>
  userRole?: Maybe<EUserRole>
  userRoleID?: Maybe<Scalars['Int']>
}

export type UserRole = {
  __typename?: 'UserRole'
  countryID: Scalars['Int']
  isActive: Scalars['Boolean']
  permission: Scalars['JSON']
  userRole: EUserRole
  userRoleID: Scalars['Int']
}

export type UserRoleRes = Response & {
  __typename?: 'UserRoleRes'
  countryID?: Maybe<Scalars['Int']>
  isActive?: Maybe<Scalars['Boolean']>
  permission?: Maybe<Scalars['JSON']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  userRole?: Maybe<EUserRole>
  userRoleID?: Maybe<Scalars['Int']>
}

export type Zone = {
  __typename?: 'Zone'
  countryID?: Maybe<Scalars['Int']>
  isActive?: Maybe<Scalars['Boolean']>
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type ZoneRes = Response & {
  __typename?: 'ZoneRes'
  countryID?: Maybe<Scalars['Int']>
  isActive?: Maybe<Scalars['Boolean']>
  res_code: Scalars['String']
  res_desc: Scalars['String']
  zoneID?: Maybe<Scalars['Int']>
  zoneName?: Maybe<Scalars['String']>
}

export type CampaignTargetTypeValueGet = {
  __typename?: 'campaignTargetTypeValueGet'
  targetTypeID?: Maybe<Scalars['Int']>
  targetTypeName?: Maybe<Scalars['String']>
}

export type LevelProduct = {
  __typename?: 'levelProduct'
  level: Scalars['String']
  point: Scalars['Int']
}
