// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'

// Images
import correctPNG from 'images/CallFromDMS/correct.png'

// Include in project
import { SelectSingle, Button } from 'component'
import styles from './index.module.scss'
import { useFilterYearMonth } from 'utils/other'

interface Props {
  reviewPointButtonStatus: boolean
  funtionOnclick: () => void
}

const HeadOfCMS: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const { values } = useFormikContext<any>()
  const { monthListOption, yearsListOption } = useFilterYearMonth()
  const disabledCallDMS = () => {
    const nowMonth = new Date().getMonth()
    const nowYear = new Date().getFullYear()

    if (values.filter.year < nowYear) return false
    if (values.filter.year === nowYear && values.filter.month <= nowMonth) return false
    else return true
  }

  return (
    <div className={styles.headSection}>
      <div className={styles.boxSelect}>
        <div className={styles.selectRow}>
          <SelectSingle name="filter.year" option={yearsListOption} disabled={false} />
          <SelectSingle name="filter.month" option={monthListOption} disabled={false} />
        </div>
        {props.reviewPointButtonStatus ? (
          <div className={styles.completeQurry}>
            <img className={styles.photo} src={correctPNG} alt="correctPNG" />
            <q>Complete: Query from DMS</q>
          </div>
        ) : (
          ''
        )}
      </div>
      {props.reviewPointButtonStatus ? (
        <Button
          name="Review: Calulate Point"
          typeButton={'button'}
          type={'primary'}
          disabled={false}
          functionOnClick={() => navigate('/check-point/campaign-list')}
        />
      ) : (
        <Button
          functionOnClick={() => props.funtionOnclick()}
          name="Query data from DMS"
          typeButton={'button'}
          type={'outline'}
          disabled={disabledCallDMS()}
        />
      )}
    </div>
  )
}

export default HeadOfCMS
