import { gql } from '@apollo/client'

export const GET_LIST_SHOP_BIG_RETAILER = gql`
  query ListShopBigRetailer($pageSize: Int!, $nextToken: String, $filter: FilterListShopBigRetailerInput) {
    listShopBigRetailer(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      nextToken
      items {
        bigRetailerID
        bigRetailerCode
        bigRetailerName
        ownRetailerID
        ownRetailerName
        tagList {
          tagID
          tagName
        }
        zaloID
        phoneNumber
        email
        sourceType
        countryID
        isActive
        zoneID
        zoneName
        provinceID
        provinceName
        districtID
        districtName
        segmentID
        segmentName
        addressID
      }
    }
  }
`

export const CREATE_SHOP_BIG_RETAILER = gql`
  mutation CreateShopBigRetailer($input: CreateShopBigRetailerInput!) {
    createShopBigRetailer(input: $input) {
      res_code
      res_desc
      bigRetailerID
      bigRetailerCode
      bigRetailerName
      ownRetailerID
      ownRetailerName
      tagList {
        tagID
        tagName
      }
      zaloID
      phoneNumber
      email
      sourceType
      countryID
      isActive
    }
  }
`

export const UPDATE_SHOP_BIG_RETAILER = gql`
  mutation UpdateShopBigRetailer($input: UpdateShopBigRetailerInput!) {
    updateShopBigRetailer(input: $input) {
      bigRetailerCode
      bigRetailerID
      bigRetailerName
      countryID
      email
      isActive
      ownRetailerID
      ownRetailerName
      phoneNumber
      res_code
      res_desc
      sourceType
      tagList {
        isActive
        tagColor
        tagID
        tagName
        typeTag
      }
      zaloID
    }
  }
`

export const DELETE_SHOP_BIG_RETAILER = gql`
  mutation DeleteShopBigRetailer($input: DeleteShopBigRetailerInput!) {
    deleteShopBigRetailer(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVERY_SHOP_BIG_RETAILER = gql`
  mutation RecoveryShopBigRetailer($input: RecoveryShopBigRetailerInput!) {
    recoveryShopBigRetailer(input: $input) {
      res_code
      res_desc
    }
  }
`
